import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const link = createHttpLink({
	uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
	link,
	cache,
});
