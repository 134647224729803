/** @jsxRuntime classic */
/** @jsx jsx */

//for the material menu
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UBC from './UBC.svg';
import FenotWhite from './FenotWhite.svg';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { Link as RouterLink } from 'react-router-dom';

import { css, jsx } from '@emotion/core';

const GET_THEMES = gql`
	query {
		themesPages(sort: "ThemesSlug:asc") {
			ThemesSlug
			ThemesHeroCard {
				NavigationTitle
			}
		}
	}
`;

export function Footer() {
	// mobile breakpoint (600px = Material-UI "xs" breakpoint)
	const mobileBreakpoint = 600;
	// "isMobile" is true when window size < 600px
	const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);

	const CustomButton = withStyles((theme) => ({
		root: {
			fontSize: '23px',
			color: 'white',
			justifyContent: 'flex-start',
			padding: '0 0',
			textTransform: 'none',
			fontFamily: 'Rokkitt',
			lineHeight: '26px',
			opacity: '0.4',
			margin: '8px 0 0',
		},
	}))(Button);

	const CustomTypography = withStyles((theme) => ({
		root: {
			color: 'white',
			fontFamily: 'Helvetica Neue',
		},
	}))(Typography);

	const CustomTitleTypography = withStyles((theme) => ({
		root: {
			color: 'white',
			fontFamily: 'Rokkitt',
			margin: '8px 0 0',
		},
	}))(Typography);

	const CustomSubButton = withStyles((theme) => ({
		root: {
			fontSize: isMobile ? '14px' : '13px',
			fontWeight: '400',
			color: 'white',
			justifyContent: 'flex-start',
			padding: '2px 25px',
			textTransform: 'none',
			lineHeight: '1.4',
		},
	}))(Button);

	const { data, loading } = useQuery(GET_THEMES);
	if (loading) return null;

	return (
		<div
			className="footer"
			container
			css={css`
				margin-top: auto;
			`}
		>
			<Grid
				container
				css={css`
					background-color: #294772;
					padding: 1% 0 0 18%;
					@media (max-width: ${mobileBreakpoint}px) {
						padding: 5%;
					}
				`}
			>
				<Grid
					item
					xs={12}
					sm={6}
					md={3}
					lg={3}
					css={css`
						padding-top: 2%;
						padding-bottom: 3%;
						@media (max-width: ${mobileBreakpoint}px) {
							padding-bottom: 10%;
						}
					`}
				>
					<Grid container direction={'column'}>
						<CustomButton
							aria-controls="simple-menu"
							disableRipple
							disabled
							css={css`
								color: white !important;
							`}
						>
							About
						</CustomButton>
						<CustomSubButton
							aria-controls="simple-menu"
							disableRipple
							component={RouterLink}
							to="/history"
						>
							History
						</CustomSubButton>
						<CustomSubButton
							aria-controls="simple-menu"
							disableRipple
							component={RouterLink}
							to="/partners"
						>
							Partners
						</CustomSubButton>
						<CustomButton
							aria-controls="simple-menu"
							disableRipple
							component={RouterLink}
							to="/ourteam"
							css={css`
								opacity: 1 !important;
							`}
						>
							Our Team
						</CustomButton>
						<CustomButton
							aria-controls="simple-menu"
							disableRipple
							disabled
							css={css`
								color: white !important;
							`}
						>
							Themes
						</CustomButton>
						{data.themesPages.map((themes, index) => (
							<CustomSubButton
								aria-controls="simple-menu"
								disableRipple
								component={RouterLink}
								to={`/themes/${themes.ThemesSlug}`}
								key={index}
							>
								{themes.ThemesHeroCard.NavigationTitle}
							</CustomSubButton>
						))}
						<CustomButton
							aria-controls="simple-menu"
							disableRipple
							component={RouterLink}
							to="/resources"
							css={css`
								opacity: 1 !important;
							`}
						>
							Resources
						</CustomButton>
						<CustomButton
							aria-controls="simple-menu"
							disableRipple
							component={RouterLink}
							to="/privacy-policy"
							css={css`
								opacity: 1 !important;
								font-size: 1rem !important;
							`}
						>
							Privacy Policy
						</CustomButton>
					</Grid>
				</Grid>
				<Grid item xs={0} sm={1} md={3} lg={3}></Grid>
				<Grid
					item
					xs={6}
					sm={3}
					md={3}
					lg={3}
					css={css`
						padding-top: 2%;
						padding-bottom: 3%;
					`}
				>
					<CustomTitleTypography variant="h5">
						<strong>Fenot</strong>
					</CustomTitleTypography>
					<CustomTypography variant="body1">
						Contact:
					</CustomTypography>
					<CustomTypography variant="body1">
						Peter Berman
					</CustomTypography>
					<a
						href={`mailto: peter.berman@ubc.ca`}
						css={css`
							text-decoration: none;
						`}
					>
						<CustomTypography variant="body1">
							peter.berman@ubc.ca
						</CustomTypography>
					</a>
					{!isMobile && (
						<CustomTypography
							variant="h6"
							css={css`
								padding-top: 12%;
							`}
						>
							Fenot © 2020
						</CustomTypography>
					)}
				</Grid>
				<Grid
					item
					xs={6}
					sm={3}
					md={3}
					lg={3}
					css={css`
						padding-top: 3%;
						padding-bottom: 3%;
					`}
				>
					<Grid
						container
						direction={isMobile ? '' : 'column'}
						spacing={isMobile ? '2' : '4'}
						justify={isMobile ? 'flex-end' : ''}
					>
						<Grid item>
							<img src={UBC} alt="UBCLogo"></img>
						</Grid>
						<Grid item>
							<img src={FenotWhite} alt="FenotLogo"></img>
						</Grid>
					</Grid>
				</Grid>
				{isMobile && (
					<CustomTypography
						variant="h6"
						css={css`
							width: 100%;
							font-size: 12px !important;
							text-align: center;
							margin-top: 20px !important;
						`}
					>
						Fenot © 2020
					</CustomTypography>
				)}
			</Grid>
		</div>
	);
}
