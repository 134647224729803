import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./Routes.js";
import { client } from "./ApolloClient";
import { ApolloProvider } from "@apollo/client";

ReactDOM.render(
	//commented out because
	//<React.StrictMode>
	<ApolloProvider className="App" client={client}>
		<Routes />
	</ApolloProvider>,
	//</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
