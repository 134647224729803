// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Hero } from './Hero.js';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Footer } from './Footer.js';
import Teal from './teal.svg';
import { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledLinkWrapper from './assets/StyledLinkWrapper';

// mobile breakpoint (600px = Material-UI "xs" breakpoint)
const mobileBreakpoint = 600;

//const Carousel = React.lazy(() => import('react-material-ui-carousel'));

const HorizontalLineStyling = css`
	border-top: 7px solid #2a4772;
	width: 25%;
	margin: 1% 0 2% 0;
	@media (max-width: ${mobileBreakpoint}px) {
		width: 97%;
		margin: 5% 0 5% 0;
	}
`;

const ThemesFirstDescription = ({
	data: { Title, Year, Description },
	isMobile,
}) => (
	<div>
		{isMobile ? (
			<Grid container>
				<Grid item xs={1}>
					<div
						className="team-column solid"
						css={css`
							 {
								height: 100%;
								width: 90%;
								background-image: url(${Teal});
								background-position: left;
								background-size: 800px;
								background-repeat: no-repeat;
							}
						`}
					></div>
				</Grid>
				<Grid item xs={11}>
					<Grid container spacing={1}>
						<Grid item xs={11}>
							<h1
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
										@media (max-width: 600px) {
											font-size: 28px;
											line-height: 36px;
										}
									}
								`}
							>
								{Title}
							</h1>
						</Grid>
						<Grid item xs={12}>
							<h2
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
									}
								`}
							>
								{Year}
							</h2>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={1}></Grid>
						<Grid item xs={10}>
							<hr css={HorizontalLineStyling}></hr>
							<div
								css={css`
									 {
										p {
											margin-block-start: 0;
											margin-block-end: 0;
										}
										line-height: 30px;
										font-size: 18px;
										font-weight: 500;
									}
								`}
								dangerouslySetInnerHTML={{
									__html: Description,
								}}
							></div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		) : (
			<Grid container>
				<Grid item xs={1}>
					<div
						className="solid"
						css={css`
							 {
								height: 100%;
								width: 90%;
								background-image: url(${Teal});
								background-position: left;
								background-size: 800px;
								background-repeat: no-repeat;
							}
						`}
					></div>
				</Grid>
				<Grid item sm={10}>
					<Grid container spacing={1}>
						<Grid item>
							<h1
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
									}
								`}
							>
								{Title}
							</h1>
						</Grid>
						<Grid item>
							<h2
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
									}
								`}
							>
								{Year}
							</h2>
						</Grid>
						<Grid item sm={12}>
							<hr css={HorizontalLineStyling}></hr>
							<div
								css={css`
									 {
										p {
											margin-block-start: 0;
											margin-block-end: 0;
										}
										line-height: 30px;
										font-size: 18px;
										font-weight: 500;
									}
								`}
								dangerouslySetInnerHTML={{
									__html: Description,
								}}
							></div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)}
	</div>
);

const UnorderedBullets = ({ data }) => (
	<div>
		<Grid container>
			<Grid item xs={1}></Grid>
			<Grid item xs={11}>
				<Grid container spacing={3}>
					<Grid item xs={11}>
						<h3
							css={css`
								 {
									margin-block-start: 0;
									margin-block-end: 0;
									font-family: 'Roboto Slab';
								}
							`}
						>
							{data.Title}
						</h3>
					</Grid>
					<Grid item xs={11}>
						{data.SimpleBullet.map((SimpleBullet, index) => (
							<div key={index}>
								<li
									css={css`
										 {
											margin-block-start: 0;
											margin-block-end: 0;
											line-height: 24px;
											font-size: 16px;
											font-weight: 300;
											color: #353535;
											padding-bottom: 2%;
										}
									`}
								>
									{SimpleBullet.Bullet.replace(/(<([^>]+)>)/gi, '')}
								</li>
							</div>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</div>
);

const OrderedBullets = ({ data }) => (
	<div>
		<Grid container>
			<Grid item xs={1}></Grid>
			<Grid item xs={11}>
				<Grid container spacing={1}>
					<Grid item xs={11}>
						<h3
							css={css`
								 {
									margin-block-start: 0;
									margin-block-end: 0;
									font-family: 'Roboto Slab';
								}
							`}
						>
							{data.Title}
						</h3>
					</Grid>
					<Grid item xs={11}>
						<ol
							css={css`
								 {
									padding-left: 1em;
								}
							`}
						>
							{data.BulletPoint.map((BulletPoint, index) => (
								<div key={index}>
									<li
										css={css`
											 {
												color: #2a4772;
												font-weight: bold;
												strong {
													color: #2a4772;
												}
												a > strong {
													color: #549ca4 !important;
												}
											}
										`}
									>
										<span
											css={css`
												 {
													font-weight: 300;
													color: #353535;
												}
											`}
										>
											<div
												css={css`
													 {
														margin-block-start: 0;
														margin-block-end: 0;
														line-height: 24px;
														font-size: 16px;
													}
												`}
												dangerouslySetInnerHTML={{
													__html: BulletPoint.Description,
												}}
											></div>
										</span>
									</li>
									{/* {BulletPoint.KeyToolsMethods ? (
										<p
											css={css`
												 {
													margin-block-start: 0;
													margin-block-end: 0;
													line-height: 24px;
													font-size: 16px;
													font-weight: 300;
													color: #353535;
												}
											`}
										>
											<strong>Key Tools/Methods: </strong>
											{BulletPoint.KeyToolsMethods}
										</p>
									) : (
										<br></br>
									)} */}
									{BulletPoint.SubBulletPoint.length ? (
										<div>
											<ol
												type="a"
												css={css`
													 {
														padding-bottom: 2%;
													}
												`}
											>
												{BulletPoint.SubBulletPoint.map((SubBulletPoint) => (
													<li
														css={css`
															 {
																margin-block-start: 0;
																margin-block-end: 0;
																line-height: 24px;
																font-size: 16px;
																color: #2a4772;
																font-weight: bold;
															}
														`}
													>
														<span
															css={css`
																 {
																	font-weight: normal;
																	color: #353535;
																}
															`}
														>
															{SubBulletPoint.Description}
														</span>
													</li>
												))}
											</ol>
										</div>
									) : null}
								</div>
							))}
						</ol>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</div>
);

export function Themes(props) {
	useEffect(() => {
		document.title = props.title || 'Fenot';
	}, [props.title]);

	let { slug } = useParams();

	const GET_THEMES = gql`
		query {
			themesPages(where: { ThemesSlug: "${slug}" }) {
				ThemesHeroCard{
      				Title
      				Image{
        				formats
        				url
      				}
    			}
    			ThemesFirstDescription{
      				Title
      				Year
     				Description
			    }
			    OrderedBulletPoints{
			      Title
			      BulletPoint{
			        Description
			        SubBulletPoint{
			          Description
			        }
			      }
			    }
			    UnorderedBulletPoints{
			      Title
			      SimpleBullet{
			        Bullet
			      }
			    }
			   
			}
		}
	`;

	// "isMobile" is true when window size < 600px
	const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);

	//for developing and testing purposes, use false, otherwise when ready to deploy use true or comment out and delete from the if statement
	const [minimumTimeElapsed] = useState(true);

	const { data, loading } = useQuery(GET_THEMES);
	if (!minimumTimeElapsed || loading)
		return (
			<div
				css={css`
					background-color: #deebed;
				`}
			>
				<div
					css={css`
						padding: 2% 3% 2.5% 3%;
						background-color: rgb(84, 156, 164);
					`}
				>
					<Skeleton height={100} width={`80%`} />
				</div>
				<ul
					className="list"
					css={css`
						list-style-type: none;
						padding: 0% 3% 3% 3%;
					`}
				>
					{Array(1)
						.fill()
						.map((item, index) => (
							<li className="card" key={index}>
								<Grid
									container
									css={css`
										padding-bottom: 3%;
									`}
								>
									<Grid item xs={1}>
										<Skeleton width={`95%`} height={200} />
									</Grid>
									<Grid item xs={8}>
										<Skeleton width={`95%`} height={200} />
									</Grid>
								</Grid>

								{Array(5)
									.fill()
									.map((item, index) => (
										<Grid
											container
											css={css`
												padding: 0 0 0 6%;
											`}
											key={index}
										>
											<Grid item xs={1}>
												<Skeleton width={`95%`} height={50} />
											</Grid>
											<Grid item xs={11}>
												<Skeleton width={`95%`} height={50} />
											</Grid>
										</Grid>
									))}
							</li>
						))}
				</ul>
				<div
					css={css`
						background-color: #294772;
						padding: 2% 4%;
					`}
				>
					<Skeleton height={100} width={`95%`} />
				</div>
			</div>
		);

	return (
		<div
			className="Themes"
			css={css`
				min-height: calc(100vh - 82px);
				display: flex;
				flex-direction: column;
			`}
		>
			<StyledLinkWrapper>
				<Grid container css={css``}>
					{data.themesPages[0].ThemesHeroCard ? (
						<Grid
							item
							xs={12}
							css={css`
								color: white;
								background: linear-gradient(to right, #373b44, #4286f4);
							`}
						>
							<Hero data={data.themesPages[0].ThemesHeroCard} />
						</Grid>
					) : null}
					{data.themesPages[0].ThemesFirstDescription ? (
						<Grid
							item
							xs={12}
							css={css`
								padding-top: 4%;
								background-color: #deebed;
								color: #2a4772;
							`}
						>
							<ThemesFirstDescription
								data={data.themesPages[0].ThemesFirstDescription}
								isMobile={isMobile}
							/>
						</Grid>
					) : null}
					{data.themesPages[0].UnorderedBulletPoints.length ? (
						<Grid
							item
							xs={12}
							css={css`
								padding-top: 3%;
								background-color: #deebed;
								color: #2a4772;
							`}
						>
							<UnorderedBullets
								data={data.themesPages[0].UnorderedBulletPoints[0]}
							/>
						</Grid>
					) : null}
					{data.themesPages[0].OrderedBulletPoints ? (
						<Grid
							item
							xs={12}
							css={css`
								background-color: #deebed;
								color: #2a4772;
								padding-top: 4%;
								padding-bottom: 4%;
							`}
						>
							<OrderedBullets data={data.themesPages[0].OrderedBulletPoints} />
						</Grid>
					) : null}
				</Grid>
			</StyledLinkWrapper>
			<Footer />
		</div>
	);
}

export default Themes;
