// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import './Resources.css';
import { useQuery } from '@apollo/client';
import { Hero } from './Hero.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Teal from './teal3.svg';
import { useEffect, useState, useRef } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchIcon from '@material-ui/icons/Search';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import FiltersIcon from './FiltersIcon.svg';
import CloseIcon from '@material-ui/icons/Close';
import { Footer } from './Footer.js';
import StyledLinkWrapper from './assets/StyledLinkWrapper';
import TextField from '@material-ui/core/TextField';

// mobile/tablet breakpoint (600px = "sm", 960px = "md")
const breakpoint = { mobile: 600, tablet: 960 };

const GET_RESOURCES = gql`
	query {
		resource {
			ResourcesHeroCard {
				Title
				Image {
					formats
					url
				}
			}
			ResourceList {
				ResourceCard {
					Image {
						url
						formats
					}
					Title
					Date
					PublicationType
					Objectives
					URL
					SourcePDF {
						url
					}
				}
				ResourceListTitle
			}
		}
	}
`;

const TitleTypography = withStyles({
	root: {
		fontSize: '24px',
		fontWeight: 'bold',
		lineHeight: '30px',
		color: '#FFFFFF',
		'@media (max-width: 600px)': {
			fontSize: '18px',
			lineHeight: '26px',
		},
	},
})(Typography);

const YearTypography = withStyles({
	root: {
		fontSize: '18px',
		lineHeight: '30px',
		color: '#FFFFFF',
		'@media (max-width: 600px)': {
			fontSize: '16px',
			lineHeight: '26px',
		},
	},
})(Typography);

const FiltersFormControlLabel = withStyles({
	root: {
		color: '#5198a5',
		fontSize: '20px',
		margin: '0 0 15px 0',
		lineHeight: '1.4',
		'@media (max-width: 960px)': {
			margin: '0 0 15px 7px',
		},
	},
})(FormControlLabel);

const useStyles = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		padding: 0,
	},
	icon: {
		borderRadius: 0,
		width: 25,
		height: 25,
		margin: '0 9px 0 0',
		backgroundColor: 'white',
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#2a4772',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 25,
			height: 25,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
});

const Filter = withStyles((theme) => ({
	root: {
		width: 38,
		height: 22,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		color: '#2a4772',
		'&$checked': {
			transform: 'translateX(16px)',
			color: '#549ca4',
			'& + $track': {
				backgroundColor: theme.palette.grey[50],
				opacity: 1,
				border: '1px solid #549ca4',
			},
		},
		'&$focusVisible $thumb': {
			color: '#52d869',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 20,
		height: 20,
	},
	track: {
		borderRadius: 22 / 2,
		border: `1px solid #2a4772`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

function ResourcesBlock({ data }) {
	const [inputText, setInputText] = useState('');
	const [state, setState] = useState({
		checkRecent: true,
	});
	const clearSearch = () => {
		setInputText('');
	};
	const doSearch = (event) => {
		setInputText(event.target.value);
	};
	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const classes = useStyles();

	//initializing the state for all the checkboxes
	const [stateCheckBox, setStateCheckBox] = useState({
		PeerReviewedPublication: false,
		Reports: false,
		Abstracts: false,
		Presentations: false,
		Objective1: false,
		Objective2: false,
		Objective3: false,
	});

	const [dateState, setDateState] = useState({ from: '2010', to: '2021' });

	const handleDateChange = (e, action) => {
		if (e.target.value.length !== 4 && e.target.value !== '') return;
		if (e.target.value === '' && action === 'from') {
			setDateState({ ...dateState, from: 0 });
			return;
		}
		if (e.target.value === '' && action === 'to') {
			// because... sci fi
			setDateState({ ...dateState, to: 2048 });
			return;
		}

		if (action === 'from') setDateState({ ...dateState, from: e.target.value });
		if (action === 'to') setDateState({ ...dateState, to: e.target.value });
	};

	//whenever a checbox is checked, the state is changed in here
	const handleChangeCheckBox = (event) => {
		setStateCheckBox({
			...stateCheckBox,
			[event.target.name]: event.target.checked,
		});
	};

	const {
		PeerReviewedPublication,
		Reports,
		Abstracts,
		Presentations,
		Objective1,
		Objective2,
		Objective3,
	} = stateCheckBox;

	//this is where all the filtering/searching/toggling happens
	const ResourcesListFiltered = (ResourceCard) => {
		// list to progressively store all the matched filters
		let filteredCards = [];

		// list of filters in use
		const filterCategories = [
			{
				checkBoxName: 'PeerReviewedPublication',
				cardKey: 'PublicationType',
				cardValue: 'PeerReviewedPublication',
			},
			{
				checkBoxName: 'Reports',
				cardKey: 'PublicationType',
				cardValue: 'Reports',
			},
			{
				checkBoxName: 'Abstracts',
				cardKey: 'PublicationType',
				cardValue: 'Abstracts',
			},
			{
				checkBoxName: 'Presentations',
				cardKey: 'PublicationType',
				cardValue: 'Presentations',
			},
			{
				checkBoxName: 'Objective1',
				cardKey: 'Objectives',
				cardValue: 'Objective1EvidenceToPolicy',
			},
			{
				checkBoxName: 'Objective2',
				cardKey: 'Objectives',
				cardValue: 'Objective2HealthCareFinancing',
			},
			{
				checkBoxName: 'Objective3',
				cardKey: 'Objectives',
				cardValue: 'OtherObjectives',
			},
		];

		// Loop through each filter and add it to filteredCards if matched
		filterCategories.forEach((category) => {
			if (stateCheckBox[category.checkBoxName]) {
				const filtered = ResourceCard.filter(
					(r) => r[category.cardKey] === category.cardValue
				);
				filteredCards = [...filteredCards, ...filtered];
			}
		});

		// Remove duplicate entries in filteredCards
		filteredCards = Array.from(new Set(filteredCards));

		//concat all the arrays we created for the checkbox filter and put them inside the finalFilteredCards
		//if a checkbox is not checked, the array corresponding to it will be empty, otherwise
		//it will have the results of the filtering
		let finalFilteredCards = filteredCards;

		//this if statement is for not getting an empty array when we first open the page;
		//if all the checkboxes are unchecked, than we put filteredCards into the array we will return
		if (
			!stateCheckBox.PeerReviewedPublication &&
			!stateCheckBox.Reports &&
			!stateCheckBox.Abstracts &&
			!stateCheckBox.Presentations &&
			!stateCheckBox.Objective1 &&
			!stateCheckBox.Objective2 &&
			!stateCheckBox.Objective3
		) {
			finalFilteredCards = [...ResourceCard];
		}

		//the toggle filters are applied to the finalFilteredCards (A-Z and recent)
		if (state.checkRecent) {
			finalFilteredCards.sort((a, b) => {
				const aDate = new Date(a.Date);
				const bDate = new Date(b.Date);
				return aDate < bDate ? 1 : bDate < aDate ? -1 : 0;
			});
		} else {
			finalFilteredCards.sort((a, b) =>
				a.Title.toLowerCase() > b.Title.toLowerCase()
					? 1
					: b.Title.toLowerCase() > a.Title.toLowerCase()
					? -1
					: 0
			);
		}

		finalFilteredCards = finalFilteredCards.filter((card) => {
			let filterMe = false;
			// need to fix time issue with first day of the year
			const incorrectDate = new Date(card.Date);
			const correctDate = parseInt(
				new Date(
					incorrectDate.getTime() +
						Math.abs(incorrectDate.getTimezoneOffset() * 60000)
				).getFullYear()
			);
			if (dateState.from && !dateState.to) {
				if (correctDate >= parseInt(dateState.from)) filterMe = true;
			}
			if (dateState.to && !dateState.from) {
				if (correctDate <= parseInt(dateState.to)) filterMe = true;
			}
			if (dateState.to && dateState.from) {
				if (
					correctDate >= parseInt(dateState.from) &&
					correctDate <= parseInt(dateState.to)
				)
					filterMe = true;
			}
			return filterMe;
		});

		//search filter applied to finalFilteredCards just before we return it
		return finalFilteredCards.filter((r) =>
			r.Title.toLowerCase().includes(inputText.toLowerCase())
		);
	};

	// toggle filters show/display, only on mobile/tablet
	const [isFiltersShown, setIsFiltersShown] = useState(false);
	const filterGroupRef = useRef(null);
	const isMobile = useMediaQuery(`(max-width:${breakpoint.tablet}px)`);

	const handleClickFiltersIcon = () => {
		setIsFiltersShown(true);
		filterGroupRef.current.style.opacity = '1';
		filterGroupRef.current.style.height = '100%';
	};
	const handleClickCloseIcon = () => {
		setIsFiltersShown(false);
		filterGroupRef.current.style.opacity = '0';
		filterGroupRef.current.style.height = '0';
	};

	return (
		<div className="resources-container">
			<div // the search bar and the toggle button container
				css={css`
					width: 83vw; // the same width as the resource card
					margin: 0 auto;
					display: flex;
					@media (max-width: 1150px) {
						display: block;
					}
					@media (max-width: ${breakpoint.mobile}px) {
						width: 88vw;
					}
				`}
			>
				<Grid
					item // the search bar container
					css={css`
						@media (min-width: 1151px) {
							flex-grow: 1;
							margin-right: 10px;
						}
						@media (max-width: ${breakpoint.mobile}px) {
							margin-bottom: 10px !important;
						}
					`}
				>
					<span
						css={css`
							 {
								font-weight: bold;
								color: white;
								background-color: #8ab192;
								border: 0;
								border-radius: 50px;
								padding: 4px;
								display: flex;
								justify-content: space-between;
								align-items: center;
							}
						`}
					>
						<SearchIcon
							css={css`
								 {
									margin-left: 5px;
									@media (max-width: 700px) {
										margin-left: 1%;
									}
									position: absolute;
								}
							`}
						></SearchIcon>
						<input
							css={css`
								 {
									width: 100%;
									font-weight: bold;
									color: white;
									font-size: large;
									border-radius: 50px;
									background-color: #8ab192;
									border: 0;
									padding: 5px;
									margin: 0px 0px 0px 25px;
									::placeholder {
										color: white;
										position: relative;
									}
									:focus {
										outline: none;
									}
									:focus::placeholder {
										color: transparent;
									}

									::-webkit-search-cancel-button {
										-webkit-appearance: none;
									}
									@media (max-width: 700px) {
										margin: 0% 0% 0% 6%;
										padding-left: 4%;
									}
									@media (max-width: 281px) {
										padding-left: 8%;
										width: 80%;
									}
								}
							`}
							type="search"
							placeholder="Search"
							value={inputText}
							onChange={doSearch}
							aria-hidden="true"
						/>
						<ClearIcon
							css={css`
								 {
									margin-right: 1%;
									@media (max-width: 700px) {
										margin-right: 2%;
									}
									color: white;
									cursor: pointer;
								}
							`}
							onClick={clearSearch}
						></ClearIcon>
					</span>
				</Grid>
				<Grid
					item // the filter icon and toggle button container
					css={css`
						@media (max-width: ${breakpoint.tablet}px) {
							display: flex;
							justify-content: space-between;
							align-items: center;
						}
						@media (max-width: ${breakpoint.mobile}px) {
							margin-bottom: 6%!important;
						}
					}
				`}
				>
					{/* Filter icon & Close icon*/}
					{isMobile && (
						<div
							css={css`
								 {
									font-family: 'Roboto Slab', sans-serif;
									color: #549ca4;
									font-size: 14px;
									font-weight: bold;
									display: flex;
									align-items: center;
								}
							`}
						>
							{/* Filter icon */}
							{!isFiltersShown && (
								<span
									onClick={handleClickFiltersIcon}
									css={css`
										 {
											display: flex;
											align-items: center;
										}
									`}
								>
									<img
										src={FiltersIcon}
										style={{
											marginRight: '8px',
											width: '23px',
										}}
										alt="filter icon"
									/>
									FILTERS
								</span>
							)}
							{/* Close icon */}
							{isFiltersShown && (
								<span
									onClick={handleClickCloseIcon}
									css={css`
										 {
											display: flex;
											align-items: center;
										}
									`}
								>
									<CloseIcon
										fontSize="large"
										css={css`
											 {
												margin-left: -4%;
												margin-right: -3px;
											}
										`}
									/>
									COLLAPSE FILTERS
								</span>
							)}
						</div>
					)}

					{/* "A-Z" and "RECENT" toggle button */}
					<div
						css={css`
							 {
								margin-left: 15px;
								display: flex;
								align-items: center;
								justify-content: flex-end;
								/* only for iphone 5/SE */
								@media (max-width: 320px) {
									margin-left: 0;
									width: 100%;
								}
							}
						`}
					>
						<h5
							css={css`
								 {
									font-family: 'Roboto Slab';
									display: inline-block;
									color: #2a4772;
									margin: 0%;
									font-size: 14px;
								}
							`}
						>
							A-Z
						</h5>
						<Filter
							className="alphabetical-sort"
							checked={state.checkRecent}
							onChange={(e) => handleChange(e)}
							name="checkRecent"
							css={css`
								 {
									display: inline-block;
									margin: 0%;
									.MuiSwitch-track {
										height: auto !important;
									}
								}
							`}
						></Filter>
						<h5
							css={css`
								 {
									font-family: 'Roboto Slab';
									display: inline-block;
									color: #549ca4;
									margin: 0%;
									font-size: 14px;
								}
							`}
						>
							RECENT
						</h5>
					</div>
				</Grid>
			</div>
			{/* Result cards and filters container */}
			<Grid
				container
				direction="row-reverse"
				css={css`
			{
				width: 83vw !important; /* the same width as the search bar */
				margin: 4% auto 0;
				justify-content: space-between;
				@media (max-width: ${breakpoint.mobile}px) {
					margin-top: 0;
					width: 88vw !important;
					}
				}
			}`}
			>
				<Grid
					item
					css={css`
						 {
							transition: 150ms; /* for filters display/hide toggle transition */
							transform-origin: top;
							/* on mobile, the filters are hidden by default */
							${isMobile && 'height: 0; opacity: 0;'}
							/* when user changes the window size to larger (>600px), make the filters visible anyways */
					${!isMobile && 'height: 100%; opacity: 1;'}

					@media (min-width: ${breakpoint.tablet}px) {
								width: 23vw;
							}
						}
					`}
					ref={filterGroupRef}
				>
					<Grid
						container
						direction="column"
						css={css`
							 {
								@media (max-width: ${breakpoint.tablet}px) {
									margin-top: 0%;
								}
							}
						`}
					>
						<Grid item>
							<h3
								css={css`
									 {
										background-color: #5198a5;
										padding: 3%;
										color: white;
										font-family: 'Roboto Slab';
										font-size: 24px;
										line-height: 30px;
										margin-block-start: 0;
										margin-block-end: 0;
										margin-bottom: 20px;
										@media (max-width: ${breakpoint.tablet}px) {
											padding: 1.5% 2%;
											margin-bottom: 3%;
										}
										@media (max-width: ${breakpoint.mobile}px) {
											margin-bottom: 6%;
										}
									}
								`}
							>
								Objectives
							</h3>
							<FormGroup
								css={css`
									 {
										@media (max-width: ${breakpoint.tablet}px) {
											padding-bottom: 3%;
										}
										@media (max-width: ${breakpoint.mobile}px) {
											padding-bottom: 0;
										}
									}
								`}
							>
								<FiltersFormControlLabel
									control={
										<Checkbox
											checked={Objective1}
											onChange={handleChangeCheckBox}
											name="Objective1"
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											inputProps={{
												'aria-label': 'decorative checkbox',
											}}
										/>
									}
									label="Objective 1 - Evidence to Policy"
								/>
								<FiltersFormControlLabel
									control={
										<Checkbox
											checked={Objective2}
											onChange={handleChangeCheckBox}
											name="Objective2"
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											inputProps={{
												'aria-label': 'decorative checkbox',
											}}
										/>
									}
									label="Objective 2 - Health Care Financing"
								/>
								<FiltersFormControlLabel
									control={
										<Checkbox
											checked={Objective3}
											onChange={handleChangeCheckBox}
											name="Objective3"
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											inputProps={{
												'aria-label': 'decorative checkbox',
											}}
										/>
									}
									label="Other Objectives"
								/>
							</FormGroup>
						</Grid>
						<Grid item container>
							<Grid item xs={12}>
								<h3
									css={css`
										 {
											background-color: #5198a5;
											padding: 3%;
											color: white;
											font-family: 'Roboto Slab';
											font-size: 24px;
											line-height: 30px;
											margin-bottom: 20px;
											@media (max-width: ${breakpoint.tablet}px) {
												padding: 1.5% 2%;
												margin-bottom: 2%;
											}
											@media (max-width: ${breakpoint.mobile}px) {
												margin-bottom: 6%;
											}
										}
									`}
								>
									Date Range
								</h3>
							</Grid>
							<Grid
								item
								xs={6}
								justify="center"
								css={css`
									 {
										margin-top: 1%;
									}
								`}
							>
								<p
									css={css`
										 {
											color: #5198a5;
											font-size: 20px;
											font-weight: 400;
											margin-block-end: 0;
											margin-block-start: 0;
											margin-bottom: 12px;
											padding: 3% 0;
											@media (max-width: ${breakpoint.tablet}px) {
												margin: 0 0 13px 7px;
											}
										}
									`}
								>
									Start Year
								</p>
							</Grid>
							<Grid
								item
								xs={6}
								css={css`
									 {
									}
								`}
							>
								<TextField
									css={css`
										 {
											.MuiInput-underline:before {
												border-bottom: 0px;
											}
											.MuiInputBase-input {
												color: #5198a5;
												font-size: 20px;
												font-weight: 400;
												padding-left: 3%;
											}
											background-color: white;
										}
									`}
									id="from-number"
									defaultValue="2010"
									type="number"
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => handleDateChange(e, 'from')}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								justify="center"
								css={css`
									 {
										margin-top: 1%;
									}
								`}
							>
								<p
									css={css`
										 {
											color: #5198a5;
											font-size: 20px;
											font-weight: 400;
											margin-block-end: 0;
											margin-block-start: 0;
											padding: 3% 0;
											@media (max-width: ${breakpoint.tablet}px) {
												margin: 0 0 3% 7px;
											}
										}
									`}
								>
									End Year
								</p>
							</Grid>
							<Grid item xs={6}>
								<TextField
									css={css`
										 {
											.MuiInput-underline:before {
												border-bottom: 0px;
											}
											.MuiInputBase-input {
												color: #5198a5;
												font-size: 20px;
												font-weight: 400;
												padding-left: 3%;
											}
											background-color: white;
										}
									`}
									id="to-number"
									defaultValue="2021"
									type="number"
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) => handleDateChange(e, 'to')}
								/>
							</Grid>
						</Grid>
						<Grid item>
							<h3
								css={css`
									 {
										background-color: #5198a5;
										padding: 3%;
										color: white;
										font-family: 'Roboto Slab';
										font-size: 24px;
										line-height: 30px;
										margin-bottom: 20px;
										/* margin-block-end: 0; */
										@media (max-width: ${breakpoint.tablet}px) {
											padding: 1.5% 2%;
											margin-bottom: 3%;
										}
										@media (max-width: ${breakpoint.mobile}px) {
											margin-bottom: 6%;
										}
									}
								`}
							>
								Publication Type
							</h3>
							<FormGroup
								css={css`
									 {
										@media (max-width: ${breakpoint.tablet}px) {
											margin-bottom: 10%;
										}
									}
								`}
							>
								<FiltersFormControlLabel
									control={
										<Checkbox
											checked={PeerReviewedPublication}
											onChange={handleChangeCheckBox}
											name="PeerReviewedPublication"
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											inputProps={{
												'aria-label': 'decorative checkbox',
											}}
										/>
									}
									label="Peer reviewed publication"
								/>
								<FiltersFormControlLabel
									control={
										<Checkbox
											checked={Reports}
											onChange={handleChangeCheckBox}
											name="Reports"
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											inputProps={{
												'aria-label': 'decorative checkbox',
											}}
										/>
									}
									label="Reports"
								/>
								<FiltersFormControlLabel
									control={
										<Checkbox
											checked={Abstracts}
											onChange={handleChangeCheckBox}
											name="Abstracts"
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											inputProps={{
												'aria-label': 'decorative checkbox',
											}}
										/>
									}
									label="Abstracts"
								/>
								<FiltersFormControlLabel
									control={
										<Checkbox
											checked={Presentations}
											onChange={handleChangeCheckBox}
											name="Presentations"
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											inputProps={{
												'aria-label': 'decorative checkbox',
											}}
										/>
									}
									label="Presentations"
								/>
							</FormGroup>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={7} sm={12}>
					{data.map((Resources) => (
						<div
							css={css`
								 {
									font-family: 'Roboto Slab';
									font-size: 28px;
									line-height: 30px;
								}
							`}
						>
							{dateState && (
								<ResourceList
									data={ResourcesListFiltered(Resources.ResourceCard)}
								/>
							)}
						</div>
					))}
				</Grid>
			</Grid>
		</div>
	);
}

function ResourceList({ data }) {
	const options = { year: 'numeric', month: 'long' };
	if (data.length === 0) {
		return (
			<p
				css={css`
					 {
						@media (max-width: 960px) {
							margin-left: 5%;
						}
					}
				`}
			>
				NO RESULTS FOUND...
			</p>
		);
	}

	return (
		<div className="resource-list" css={css``}>
			<Grid container direction="row">
				{data.map((ResourceCard) => {
					const incorrectDate = new Date(ResourceCard.Date);
					const correctDate = new Date(
						incorrectDate.getTime() +
							Math.abs(incorrectDate.getTimezoneOffset() * 60000)
					).toLocaleDateString(undefined, options);
					return (
						<div>
							{ResourceCard.Image ? (
								<div>
									<a
										target="_blank"
										rel="noreferrer"
										href={`${
											ResourceCard.SourcePDF
												? ResourceCard.SourcePDF.url
												: ResourceCard.URL
												? ResourceCard.URL
												: '404'
										}`}
									>
										<Grid
											css={css`
												 {
													width: 58vw !important;
													@media (max-width: ${breakpoint.tablet}px) {
														width: 83vw !important;
													}
													@media (max-width: ${breakpoint.mobile}px) {
														width: 88vw !important;
													}
													margin: 0 0 2%;
													padding: 2%;
													background: linear-gradient(
															rgba(84, 156, 164, 0.6),
															rgba(84, 156, 164, 0.6)
														),
														url(${ResourceCard.Image.formats.large.url});
													background-position: center;
													background-size: cover;
													background-repeat: no-repeat;
													@media (max-width: 600px) {
														background: linear-gradient(
																rgba(84, 156, 164, 0.6),
																rgba(84, 156, 164, 0.6)
															),
															url(${ResourceCard.Image.formats.small.url});
														background-position: center;
														background-size: cover;
														background-repeat: no-repeat;
													}
												}
											`}
											container
											justify="space-between"
										>
											<Grid item xs={12} sm={9}>
												<TitleTypography variant="h5">
													{ResourceCard.Title}
												</TitleTypography>
											</Grid>
											<Grid item xs={12}>
												<Grid
													container
													justify="space-between"
													css={css`
														 {
															padding-top: 2%;
														}
													`}
													direction="row"
												>
													<Grid item></Grid>
													<Grid item>
														<YearTypography variant="h5">
															{correctDate}
														</YearTypography>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</a>
								</div>
							) : (
								<div>
									<a
										target="_blank"
										rel="noreferrer"
										href={`${
											ResourceCard.SourcePDF
												? ResourceCard.SourcePDF.url
												: ResourceCard.URL
												? ResourceCard.URL
												: '404'
										}`}
									>
										<Grid
											css={css`
												 {
													width: 58vw !important;
													@media (max-width: ${breakpoint.tablet}px) {
														width: 83vw !important;
													}
													@media (max-width: ${breakpoint.mobile}px) {
														width: 88vw !important;
													}
													margin: 0 0 2%;
													padding: 2%;
													background-image: url(${Teal});
													background-position: center;
													background-size: cover;
													background-repeat: no-repeat;
												}
											`}
											container
											justify="space-between"
										>
											<Grid item xs={12} sm={9}>
												<TitleTypography variant="h5">
													{ResourceCard.Title}
												</TitleTypography>
											</Grid>
											<Grid item xs={12}>
												<Grid
													container
													justify="space-between"
													css={css`
														 {
															padding-top: 2%;
														}
													`}
													direction="row"
												>
													<Grid item></Grid>
													<Grid item>
														<YearTypography variant="h5">
															{correctDate}
														</YearTypography>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</a>
								</div>
							)}
						</div>
					);
				})}
			</Grid>
		</div>
	);
}

export function Resources(props) {
	useEffect(() => {
		document.title = props.title || 'Fenot';
	}, [props.title]);

	const [minimumTimeElapsed] = useState(true);

	const { data, loading } = useQuery(GET_RESOURCES);

	if (!minimumTimeElapsed || loading)
		return (
			<div
				css={css`
					background-color: #deebed;
				`}
			>
				<div
					css={css`
						padding: 2% 3% 2.5% 3%;
						background-color: rgb(84, 156, 164);
					`}
				>
					<Skeleton height={100} width={`50%`} />
				</div>
				<ul
					className="list"
					css={css`
						list-style-type: none;
						padding: 2% 3%;
					`}
				>
					{Array(1)
						.fill()
						.map((item, index) => (
							<li
								css={css`
									padding: 1% 0 1% 6%;
								`}
								className="card"
								key={index}
							>
								<Grid
									container
									justify="space-between"
									css={css`
										padding: 1% 6% 1% 0;
									`}
									spacing={3}
								>
									<Grid item xs={10}>
										<Skeleton height={60} width={`100%`} />
									</Grid>
									<Grid item xs={2}>
										<Skeleton height={60} width={`100%`} />
									</Grid>
								</Grid>
								{Array(1)
									.fill()
									.map((item, index) => (
										<li css={css``} className="card" key={index}>
											<Grid
												container
												justify="space-between"
												css={css`
													padding: 1% 6% 1% 0;
												`}
												spacing={3}
											>
												<Grid item xs={9}>
													<Skeleton height={170} width={`100%`} />
													<Skeleton height={170} width={`100%`} />
													<Skeleton height={170} width={`100%`} />
													<Skeleton height={170} width={`100%`} />
													<Skeleton height={170} width={`100%`} />
												</Grid>
												<Grid item xs={3}>
													{Array(2)
														.fill()
														.map((item, index) => (
															<li key={index}>
																<Skeleton
																	height={60}
																	width={`100%`}
																	className="filterSkeleton"
																/>
																{Array(3)
																	.fill()
																	.map((item, index) => (
																		<li key={index}>
																			<Grid container spacing={3}>
																				<Grid item xs={3}>
																					<Skeleton
																						height={45}
																						width={`100%`}
																					/>
																				</Grid>
																				<Grid item xs={6}>
																					<Skeleton
																						height={45}
																						width={`100%`}
																					/>
																				</Grid>
																			</Grid>
																		</li>
																	))}
															</li>
														))}
												</Grid>
											</Grid>
										</li>
									))}
							</li>
						))}
				</ul>
				<div
					css={css`
						background-color: #294772;
						padding: 2% 4%;
					`}
				>
					<Skeleton height={100} width={`95%`} />
				</div>
			</div>
		);

	return (
		<div className="Resources">
			<StyledLinkWrapper>
				<Grid container css={css``}>
					<Grid
						item
						xs={12}
						css={css`
							color: white;
							background: linear-gradient(to right, #373b44, #4286f4);
						`}
					>
						<Hero data={data.resource.ResourcesHeroCard} />
					</Grid>
					<Grid
						item
						xs={12}
						css={css`
							padding-bottom: 4%;
							padding-top: 4%;
							background-color: #deebed;
							color: #2a4772;
						`}
					>
						<ResourcesBlock
							css={css`
								@media (min-width: 1150px) {
									padding: 10px;
								}
							`}
							data={data.resource.ResourceList}
						/>
					</Grid>
				</Grid>
			</StyledLinkWrapper>
			<Footer />
		</div>
	);
}

export default Resources;
