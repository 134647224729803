// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import { Footer } from './Footer.js';
import { Hero } from './Hero.js';
import Stock from './Stock.jpg';

export default function PravicaPolicy() {
	return (
		<div>
			<Grid container>
				<Grid
					item
					xs={12}
					css={css`
						color: white;
						background: linear-gradient(
								rgba(0, 0, 0, 0.3),
								rgba(0, 0, 0, 0.3)
							),
							url(${Stock});
						background-position: center;
						background-size: cover;
					`}
				>
					<Hero data={('', '')} />
				</Grid>
				<Grid
					item
					css={css`
							word-break: break-word;
							padding: 5% 8% 6rem;
							h1, h2, h3, h4, h5 {
								font-family: 'Roboto Slab';
								color: #2a4772;
							}
							h2 {
								margin: 3.5rem 0 1rem;
							}
							h3, h4, h5 {
								margin: 2rem 0 1rem;
							}
							h5 {
								font-size: 1rem;
							}
							p {
								margin: 0;
							}
					`}
				>
					<h1>
						Privacy Policy
					</h1>
					<div>
						<p>
							Collection, use, disclosure and retention of personal information is subject to, and must comply with, the provisions of the <a href="http://www.bclaws.ca/EPLibraries/bclaws_new/document/ID/freeside/96165_01" target="_blank" rel="noreferrer noopener">BC Freedom of Information and Protection of Privacy Act</a> (FIPPA). <br />
							The purpose of this privacy notice is to describe our practices regarding personal information use and collection through Fenot. Please note that we do not disclose, give, sell or transfer any personal information about our website visitors unless required for law enforcement or otherwise provided for by law.<br />
							<br />
							We reserve the right to update this policy at any time. Users are responsible for reviewing this document periodically for changes.
						</p>

						<h2>What is personal information?</h2>
						<p>
							Personal information is recorded information about an identifiable individual other than business contact information and would include, but is not limited to, such things as an individual's name, address, birth date, email address, and phone number.
						</p>

						<h3>Collection &amp; use of information</h3>
						<p>Fenot is authorized to collect personal information via this website under section 26 (c) of FIPPA for the purposes described below. Information is collected from our websites, either directly from you or automatically.</p>

						<h4>Information collected directly from you</h4>
						<p>
							Personal information is collected through our websites in certain circumstances, such as when you send an email message to us or complete a questionnaire, form, or other online survey found on our websites. <br />
							<br />
							We only use the personal information you provide to us through our websites for purposes consistent with its collection.<br />
							<br />
							You should also be aware that information provided by you becomes a record of the organization and is subject to the access and privacy protection requirements set out in FIPPA. <br />
							<br />
							This information may be further collected, used and disclosed in accordance with that Act or as otherwise required by law. Under this legislation you are also entitled to access and correct any of your personal information that is within our custody and control. You may make such requests by contacting the privacy representative listed on this page.
						</p>
						<h4>Information collected automatically</h4>
						<p>
							Some of our web pages use "cookies," which are small amounts of information stored by your web browser software on your work station. <br />
							<br />
							Cookies are designed to track browsing history on a website generally for the convenience of the user. They help websites "remember" what areas of a website a browser has accessed or used previously. Cookies can, however, be misused, and have become a privacy concern for some because they can be used to record and analyze an individual's broader browsing history.<br />
							<br />
							The cookies we use on our websites and what we use them for are described below. <br />
							<br />
							This site operates on "implied consent," which means that by visiting and clicking through the site, we assume you agree with this usage. If you do not agree, then you should not use our sites, or you can delete the cookies after visiting our sites, or use your browser's anonymous usage setting (called "Incognito" in Chrome, "In Private" in Internet Explorer, "Private Browsing" in Firefox and Safari). <br />
							<br />
							More information on cookies, what they are used for and how to delete cookies after visiting this site can be found at <a href="http://www.allaboutcookies.org/cookies/session-cookies-used-for.html" target="_blank" rel="noreferrer noopener">http://www.allaboutcookies.org/cookies/session-cookies-used-for.html</a>.<br />
							<br />
							It's important to know that cookies are plain text files and cannot execute files or make copies of themselves. They do not carry or deliver viruses and cannot scan or retrieve your personal information. <br />
							<br />
							There are two types of cookie - <b>Session</b> and <b>Persistent</b>.
						</p>

						<h5>Session cookies</h5>
						<p>Session cookie information is deleted as soon as you leave our websites. The use of temporary session cookies is solely for the purpose of allowing the user to interact with our websites in an effective manner.</p>

						<h5>Persistent cookies</h5>
						<p>In a few cases our website may use persistent cookies to store information for longer periods than the current session.</p>

						<h2>Analytics &amp; safeguards</h2>

						<h3>Analytics</h3>
						<p>
							Fenot uses Google Analytics, a web analytics service provided by Google, Inc. (Google).<br />
							Google Analytics uses first-party cookies to track visitor interactions. The information generated by the cookie about your use of this website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of this website, compiling reports on website activity for the website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law or where such third parties process the information on Google's behalf.<br />
							<br />
							You may refuse the use of cookies by selecting the appropriate settings on your browser - however please note that if you do this you may not be able to use the full functionality of this website.<br />
							<br />
							Fenot uses IP masking within Google Analytics to anonymize personal information collected by Google. IP masking is a customization which removes the last octet of the visitor's IP address prior to its use and storage by Google.<br />
							<br />
							Website visitors who do not want to be tracked by Google Analytics can download the Google Analytics Opt-out Browser Add-on. The add-on communicates with the Google Analytics JavaScript (ga.js) to indicate that information about the website visit should not be sent to Google Analytics. The Google Analytics Opt-out Browser Add-on does not prevent information from being sent to the website itself or to other web analytics services.<br />
							<br />
							For more information visit the <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer" >Google Analytics Overview page</a>.
						</p>

						<h3>Safeguards</h3>
						<p>Fenot is obligated to protect your personal information by making reasonable security arrangements against such risks as unauthorized access, collection, use, disclosure, or disposal. Security measures have been integrated into the design, implementation, and day-to-day operating practices as part of Fenot's continuing commitment to the protection of personal information it holds.</p>

						<h2>Related Links</h2>
						<p>Fenot websites contains links to other websites. Fenot is not responsible for the privacy practices of these websites or the accuracy or reliability of any content found on such sites.</p>
					</div>
				</Grid>
			</Grid>
			<Footer />
		</div>
	);
}
