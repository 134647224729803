// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import "./Publications.css";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Hero } from "./Hero.js";
import Grid from "@material-ui/core/Grid";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordion from "@material-ui/core/Accordion";
import Triangle from "./Triangle.png";
import { withStyles } from "@material-ui/core/styles";
import { Footer } from "./Footer.js";
import { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import StyledLinkWrapper from "./assets/StyledLinkWrapper";

// mobile breakpoint (600px = Material-UI "xs" breakpoint)
const mobileBreakpoint = 600;

const Accordion = withStyles({
	root: {
		backgroundColor: "#5198a5",
		boxShadow: "none",
		margin: "1% 6% 0 6%",

		color: "white",
		borderRadius: "4px",

		"&:before": {
			display: "none",
		},
		"&$expanded": {
			margin: "1% 6% 0 6%",
			"&:first-child": {
				marginTop: "1%",
			},
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		padding: "0 10",
		"@media (max-width:600px)": {
			padding: 0,
		},
	},
	content: {
		margin: 0,
		"&$expanded": { margin: 0 },
	},
	expandIcon: {
		color: "white",
		fontSize: "32px",
		order: -1,
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		backgroundColor: "#b1d2d7",
		color: "black",
		padding: 0,
		"&:last-child": {
			paddingBottom: "4%",
		},
	},
}))(MuiAccordionDetails);

const GET_PUBLICATIONS = gql`
	query {
		publicationsPage {
			PublicationsHeroCard {
				Title
				Image {
					url
				}
			}
			PublicationsBlock {
				Title
				Publication {
					PublicationDescription
				}
			}
		}
	}
`;

const PublicationBlock = ({ data }) => (
	<div className="publications-container" css={css``}>
		{data.map((Publications) => (
			<div>
				<Accordion>
					<AccordionSummary
						expandIcon={
							<img
								src={Triangle}
								alt="triangle"
								css={css`
									 {
										height: 30px;
										@media (max-width: ${mobileBreakpoint}px) {
											height: 16px;
										}
									}
								`}
							/>
						}
					>
						<h3
							css={css`
								 {
									margin-block-start: 0;
									margin-block-end: 0;
									font-family: "Roboto Slab";
									font-size: 32px;
									line-height: 43px;
									padding: 2% 5%;
									@media (max-width: ${mobileBreakpoint}px) {
										font-size: 22px;
										line-height: 30px;
										padding: 5% 5% 6%;
									}
								}
							`}
						>
							{Publications.Title}
						</h3>
					</AccordionSummary>
					{Publications.Publication.map((Publication) => (
						<AccordionDetails>
							<div
								css={css`
									 {
										p {
											margin-block-start: 0;
											margin-block-end: 0;
											display: list-item;
										}
										font-size: 18px;
										line-height: 30px;
										padding: 0 11%;
										margin-top: 3%;
										@media (max-width: ${mobileBreakpoint}px) {
											font-size: 16px;
											line-height: 26px;
											padding: 0 5% 0 10%;
										}
									}
								`}
								dangerouslySetInnerHTML={{
									__html: Publication.PublicationDescription,
								}}
							></div>
						</AccordionDetails>
					))}
				</Accordion>
				<br></br>
			</div>
		))}
	</div>
);

export function Publications(props) {
	useEffect(() => {
		document.title = props.title || "Fenot";
	}, [props.title]);

	const [minimumTimeElapsed] = useState(true);

	const { data, loading } = useQuery(GET_PUBLICATIONS);

	if (!minimumTimeElapsed || loading)
		return (
			<div
				css={css`
					background-color: #deebed;
				`}
			>
				<div
					css={css`
						padding: 2% 3% 2.5% 3%;
						background-color: rgb(84, 156, 164);
					`}
				>
					<Skeleton height={100} width={`50%`} />
				</div>
				<ul
					className="list"
					css={css`
						list-style-type: none;
						padding: 2% 3% 10% 3%;
					`}
				>
					{Array(3)
						.fill()
						.map((item, index) => (
							<li
								css={css`
									padding: 1% 0 1% 6%;
								`}
								className="card"
								key={index}
							>
								<Skeleton height={130} width={`94%`} />
							</li>
						))}
				</ul>
				<div
					css={css`
						background-color: #294772;
						padding: 2% 4%;
					`}
				>
					<Skeleton height={100} width={`95%`} />
				</div>
			</div>
		);

	return (
		<div
			className="Publications"
			css={css`
				min-height: calc(100vh - 82px);
				display: flex;
				flex-direction: column;
			`}
		>
			<StyledLinkWrapper>
				<Grid container css={css``}>
					<Grid
						item
						xs={12}
						css={css`
							color: white;
							background: linear-gradient(
								to right,
								#373b44,
								#4286f4
							);
						`}
					>
						<Hero
							data={data.publicationsPage.PublicationsHeroCard}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						css={css`
							background-color: #deebed;
							padding: 1.25% 0;

							color: #2a4772;
							@media (max-width: ${mobileBreakpoint}px) {
								padding-top: 7%;
							}
						`}
					>
						<PublicationBlock
							data={data.publicationsPage.PublicationsBlock}
						/>
					</Grid>
				</Grid>
			</StyledLinkWrapper>
			<Footer />
		</div>
	);
}

export default Publications;
