import styled from '@emotion/styled';

//link styling
const StyledLinkWrapper = styled.div`
	a {
		text-decoration: none;
		color: #549ca4;
	}
`;

export default StyledLinkWrapper;
