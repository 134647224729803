import { useState, useEffect } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

//for the material menu
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Logo from './Logo.png';
import LogoWhite from './LogoWhite.png';

import { Link } from 'react-router-dom';

const GET_THEMES = gql`
  query {
    themesPages(sort: "ThemesSlug:asc") {
      ThemesSlug
      ThemesHeroCard {
        NavigationTitle
      }
    }
  }
`;

export default function Nav() {
  // let location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  // collapse the navbar when any of the links is clicked
  useEffect(() => {
    document.querySelectorAll('.navContents li a').forEach((link) => {
      link.addEventListener('click', () => {
        setIsNavOpen(false);
      });
    });
  }, [isNavOpen]);

  const { data, loading } = useQuery(GET_THEMES);
  if (loading) return null;

  // disable scrolling when the nav is open
  const htmlTag = document.querySelector('html');
  if (isNavOpen) {
    htmlTag.style.cssText = 'height: 100vh; overflow: hidden;';
    document.body.style.cssText = 'height: 100vh; overflow: hidden;';
  } else {
    htmlTag.style.cssText = 'height: unset; overflow: unset;';
    document.body.style.cssText = 'height: unset; overflow: unset;';
  }

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700, // change the "sm" breakpoint of material-ui
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  // --------------------
  // CSS stylings
  // --------------------
  const LogoCSS = css`
    height: 40px;
    display: block;
    padding: 10px;
  `;

  const hamburgerIconCSS = css`
    transition: opacity 0.2s;
    color: ${isNavOpen ? '#fff' : '#353535'};
    display: ${isNavOpen ? 'none!important' : 'block'};
    opacity: ${isNavOpen ? '0' : '1'};
  `;

  const closeIconCSS = css`
    transition: opacity 0.2s;
    display: ${isNavOpen ? 'block' : 'none!important'};
    opacity: ${isNavOpen ? '1' : '0'};
  `;

  const mobileNavCSS = css`
    // navbar background color
    &.navClosed {
      transition: background-color 100ms;
      background-color: #fff !important;
    }
    &.navOpen {
      transition: background-color 100ms;
      background-color: #294772 !important;
    }

    // nav menu contents background color, height, width, opacity
    .navContents {
      position: absolute;
      top: 60px;
      left: -100vw;
      width: 100vw;
      height: 100vh;
      z-index: 9 !important;
      background-color: #294772;
      transition: left 500ms cubic-bezier(0.86, 0, 0.07, 1);
    }
    .navContentsHidden {
      left: -100vw;
    }
    .navContentsVisible {
      left: 0;
      display: block;
      width: 100vw;
      height: 100vh;
    }

    // nav menu contents
    ul {
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: #294772;
      *,
      a {
        color: #fff;
        font-family: 'Helvetica Neue' !important;
        text-decoration: none;
      }
      li {
        list-style: none;
        padding-top: 0;
        padding-bottom: 0;
      }
      li > a {
        margin: 0 0 10px 30px;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 16px;
        font-weight: 500;
      }
      h3,
      h3 > a {
        font-size: 33px;
        font-family: 'Rokkitt', sans-serif !important;
        font-weight: 500;
        margin: 15px 0 5px;
        line-height: 1;
      }
      h3:not(.ourTeamLink) {
        opacity: 0.4;
      }
      .ourTeamLink {
        opacity: 1;
      }
    }
  `;

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar
        position="sticky"
        className={isNavOpen ? 'mobileNav navOpen' : 'mobileNav navClosed'}
        elevation={0}
        css={mobileNavCSS}
      >
        <Grid container justify="space-between">
          <Grid container xs={12} alignItems="center" justify="space-between">
            <Grid item xs={4}>
              <Link to="/">
                <img
                  src={isNavOpen ? LogoWhite : Logo}
                  alt="Logo"
                  css={LogoCSS}
                  onClick={() => setIsNavOpen(false)}
                />
              </Link>
            </Grid>
            <Grid container xs={2} justify="center" alignContent="center">
              <MenuIcon
                className="menuIcon"
                style={{ fontSize: 34 }}
                onClick={() => setIsNavOpen(true)}
                css={hamburgerIconCSS}
              />
              <CloseIcon
                className="closeIcon"
                style={{ color: '#fff', fontSize: 34 }}
                onClick={() => setIsNavOpen(false)}
                css={closeIconCSS}
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={
              isNavOpen
                ? 'navContents navContentsVisible'
                : 'navContents navContentsHidden'
            }
          >
            <List>
              <ListItem>
                <h3>About</h3>
              </ListItem>
              <ListItem>
                <Link to="/history">History</Link>
              </ListItem>
              <ListItem>
                <Link to="/partners">Partners</Link>
              </ListItem>
              <ListItem>
                <h3 className="ourTeamLink">
                  <Link to="/ourteam">Our Team</Link>
                </h3>
              </ListItem>
              <ListItem>
                <h3>Themes</h3>
              </ListItem>

              {/* Themes */}
              {data.themesPages.map((themes, index, array) => {
                if (array.length - 1 === index) {
                  return (
                    <ListItem key={index}>
                      <Link to={`/themes/${themes.ThemesSlug}`}>
                        {themes.ThemesHeroCard.NavigationTitle}
                      </Link>
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem key={index}>
                      <Link to={`/themes/${themes.ThemesSlug}`}>
                        {themes.ThemesHeroCard.NavigationTitle}
                      </Link>
                    </ListItem>
                  );
                }
              })}
              {/* end of Themes */}
              <ListItem>
                <h3 className="ourTeamLink">
                  <Link to="/resources">Resources</Link>
                </h3>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </AppBar>
    </MuiThemeProvider>
  );
}
