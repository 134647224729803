// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Hero } from './Hero.js';
import { Footer } from './Footer.js';
import Grid from '@material-ui/core/Grid';
import Stock from './Stock.jpg';
//import Teal from './teal.svg';
import { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordion from '@material-ui/core/Accordion';
import Triangle from './Triangle.png';
import { withStyles } from '@material-ui/core/styles';
import TimelineDesktop from './assets/images/timeline-desktop.png';
import TimelineMobile from './assets/images/timeline-mobile.png';

// mobile breakpoint (600px = Material-UI "xs" breakpoint)
const mobileBreakpoint = 600;

//next to functions are for the Quill list indentation issue
//got this solution from https://github.com/quilljs/quill/issues/979#issuecomment-381151479

function getListLevel(el) {
	const className = el.className || '0';
	return +className.replace(/[^\d]/g, '');
}

function convertLists(richtext) {
	const tempEl = window.document.createElement('div');
	tempEl.setAttribute('style', 'display: none;');
	tempEl.innerHTML = richtext;

	['ul', 'ol'].forEach((type) => {
		const startTag = `::start${type}::::/start${type}::`;
		const endTag = `::end${type}::::/end${type}::`;

		Array.from(tempEl.querySelectorAll(type)).forEach((outerListEl) => {
			const listChildren = Array.from(outerListEl.children).filter(
				(el) => el.tagName === 'LI'
			);

			// Account for the fact that the first li might not be at level 0
			const firstLi = listChildren[0];
			firstLi.before(startTag.repeat(getListLevel(firstLi)));

			// Now work through each li in this list
			listChildren.forEach((listEl, index) => {
				const currentLiLevel = getListLevel(listEl);
				if (index < listChildren.length - 1) {
					const difference =
						getListLevel(listChildren[index + 1]) - currentLiLevel;

					// we only need to add tags if the level is changing
					if (difference > 0) {
						listChildren[index + 1].before(startTag.repeat(difference));
					} else if (difference < 0) {
						listEl.after(endTag.repeat(-difference));
					}
				} else {
					listEl.after(endTag);
				}
			});
			outerListEl.after(endTag);
		});
	});

	//  Get the content in the element and replace the temporary tags with new ones
	let newContent = tempEl.innerHTML;
	newContent = newContent.replace(/::startul::::\/startul::/g, '<ul>');
	newContent = newContent.replace(/::endul::::\/endul::/g, '</ul>');
	newContent = newContent.replace(/::startol::::\/startol::/g, '<ol>');
	newContent = newContent.replace(/::endol::::\/endol::/g, '</ol>');

	tempEl.remove();
	return newContent;
}

const Accordion = withStyles({
	root: {
		backgroundColor: '#5198a5',
		boxShadow: 'none',
		margin: '1% 6% 0 8%',

		color: 'white',
		borderRadius: '4px',

		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: '1% 6% 0 8%',
			'&:first-child': {
				marginTop: '1%',
			},
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		padding: '0 10',
		'@media (max-width:600px)': {
			padding: 0,
		},
	},
	content: {
		margin: 0,
		'&$expanded': { margin: 0 },
	},
	expandIcon: {
		color: 'white',
		fontSize: '32px',
		order: -1,
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		backgroundColor: '#b1d2d7',
		color: 'black',
		padding: 0,
		'&:last-child': {
			paddingBottom: '2%',
		},
		'& img': {
			height: '100%',
			width: '100%',
    },
		'& a': {
			textDecoration: 'none',
			color: '#549ca4',
		},
	},
}))(MuiAccordionDetails);

const GET_HISTORY = gql`
	query {
		historyPage {
			HistoryHeroCard {
				Title
				Image {
					formats
					url
				}
			}
			HistoryDescription {
				Title
				Years
				Description
			}
			HistoryBody {
				Body
			}
			HistoryBodyHeroCard {
				Title
				Image {
					formats
					url
				}
			}
			HistoryBodyBelowHero {
				Body
			}
			HistoryAccordion {
				AccordionTitle
				AccordionBody
			}
		}
	}
`;

/*const HistoryDescription = ({
	data: { Title, Years, Description },
	isMobile,
}) => (
	<div>
		{isMobile ? (
			<Grid container>
				<Grid item xs={1}>
					<div
						className="team-column solid"
						css={css`
							 {
								height: 100%;
								width: 90%;
								background-image: url(${Teal});
								background-position: left;
								background-size: 800px;
								background-repeat: no-repeat;
							}
						`}
					></div>
				</Grid>
				<Grid item xs={11}>
					<Grid container spacing={1}>
						<Grid item xs={11}>
							<h1
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
										@media (max-width: 600px) {
											font-size: 28px;
											line-height: 36px;
										}
									}
								`}
							>
								{Title}
							</h1>
						</Grid>
						<Grid item xs={12}>
							<h2
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
									}
								`}
							>
								{Years}
							</h2>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={1}></Grid>
						<Grid item xs={10}>
							<hr css={HorizontalLineStyling}></hr>
							<div
								css={css`
									 {
										p {
											margin-block-start: 0;
											margin-block-end: 0;
										}
										line-height: 30px;
										font-size: 18px;
										font-weight: 500;
									}
								`}
								dangerouslySetInnerHTML={{
									__html: Description,
								}}
							></div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		) : (
			<Grid container>
				<Grid item xs={1} sm={1}>
					<div
						className="team-column solid"
						css={css`
							 {
								height: 100%;
								width: 90%;
								background-image: url(${Teal});
								background-position: left;
								background-size: 800px;
								background-repeat: no-repeat;
							}
						`}
					></div>
				</Grid>
				<Grid item xs={11} sm={10}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={7}>
							<h1
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
									}
								`}
							>
								{Title}
							</h1>
						</Grid>
						<Grid item xs={12}>
							<h2
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										line-height: 43px;
										font-family: 'Roboto Slab';
									}
								`}
							>
								{Years}
							</h2>
						</Grid>
						<Grid item xs={12}>
							<hr css={HorizontalLineStyling}></hr>
							<div
								css={css`
									 {
										p {
											margin-block-start: 0;
											margin-block-end: 0;
										}
										line-height: 30px;
										font-size: 18px;
										font-weight: 500;
									}
								`}
								dangerouslySetInnerHTML={{
									__html: Description,
								}}
							></div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)}
	</div>
);*/

const HistoryBodyCard = ({ data }) => (
	<div>
		{data.map((HistoryBody) => (
			<Grid container>
				<Grid item xs={1}></Grid>
				<Grid item xs={10}>
					<div
						css={css`
							 {
								p {
									margin-block-start: 0;
									margin-block-end: 0;
								}
								line-height: 24px;
								font-size: 16px;
								color: #353535;
							}
						`}
						dangerouslySetInnerHTML={{ __html: HistoryBody.Body }}
					></div>
					<br></br>
				</Grid>
			</Grid>
		))}
	</div>
);

const HistoryBodyHero = ({ data }) => (
	<div>
		{data.Image ? (
			<Grid
				container
				css={css`
					color: white;
					background-image: url(${data.Image.formats.large.url});
					background-position: center;
					background-size: cover;
					padding: 4% 8% 4% 8%;
					font-family: 'Rokkitt';
				`}
			>
				<Grid item xs={11}>
					<div
						css={css`
							font-size: 48px;
							p {
								margin-block-start: 0;
								margin-block-end: 0;
							}
							line-height: 54px;
							text-align: center;
							font-weight: 700;
							@media (max-width: ${mobileBreakpoint}px) {
								font-size: 24px;
								line-height: 26px;
							}
						`}
						dangerouslySetInnerHTML={{ __html: data.Title }}
					></div>
				</Grid>
			</Grid>
		) : (
			<Grid
				container
				css={css`
					color: white;
					background-image: url(${Stock});
					background-position: center;
					background-size: cover;
					padding: 4% 8% 4% 8%;
					font-family: 'Rokkitt';
				`}
			>
				<Grid item xs={11}>
					<div
						css={css`
							font-size: 48px;
							p {
								margin-block-start: 0;
								margin-block-end: 0;
							}
							line-height: 54px;
							text-align: center;
							font-weight: 700;
							@media (max-width: ${mobileBreakpoint}px) {
								font-size: 24px;
								line-height: 26px;
							}
						`}
						dangerouslySetInnerHTML={{ __html: data.Title }}
					></div>
				</Grid>
			</Grid>
		)}
	</div>
);

const HistoryAccordionBlock = ({ data }) => (
	<div className="history-accordion-container" css={css``}>
		{data.map((HistoryAccordions) => (
			<div>
				<Accordion>
					<AccordionSummary
						expandIcon={
							<img
								src={Triangle}
								alt="triangle"
								css={css`
									 {
										height: 30px;
										@media (max-width: ${mobileBreakpoint}px) {
											height: 16px;
										}
									}
								`}
							/>
						}
					>
						<h3
							css={css`
								 {
									margin-block-start: 0;
									margin-block-end: 0;
									font-family: 'Roboto Slab';
									font-size: 32px;
									line-height: 43px;
									padding: 2% 5%;
									@media (max-width: ${mobileBreakpoint}px) {
										font-size: 22px;
										line-height: 30px;
										padding: 5% 5% 6%;
									}
								}
							`}
						>
							{HistoryAccordions.AccordionTitle}
						</h3>
					</AccordionSummary>
					<AccordionDetails>
						<div
							css={css`
								 {
									p {
										margin-block-start: 0;
										margin-block-end: 0;
									}
									font-size: 18px;
									line-height: 30px;
									padding: 0 2%;
									margin-top: 3%;
									@media (max-width: ${mobileBreakpoint}px) {
										font-size: 16px;
										line-height: 26px;
										padding: 0 5% 0 10%;
									}
								}
							`}
							dangerouslySetInnerHTML={{
								__html: convertLists(HistoryAccordions.AccordionBody),
							}}
						></div>
					</AccordionDetails>
				</Accordion>
			</div>
		))}
	</div>
);

export function History(props) {
	useEffect(() => {
		document.title = props.title || 'Fenot';
	}, [props.title]);

	const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);

	//for developing and testing purposes, use false, otherwise when ready to deploy use true or comment out and delete from the if statement
	const [minimumTimeElapsed] = useState(true);

	const { data, loading } = useQuery(GET_HISTORY);
	if (!minimumTimeElapsed || loading)
		return (
			<div
				css={css`
					background-color: #deebed;
				`}
			>
				<div
					css={css`
						padding: 2% 3% 2.5% 3%;
						background-color: rgb(84, 156, 164);
					`}
				>
					<Skeleton height={100} width={`30%`} />
				</div>
				<ul
					className="list"
					css={css`
						list-style-type: none;
						padding: 2% 3%;
					`}
				>
					{Array(1)
						.fill()
						.map((item, index) => (
							<li className="card" key={index}>
								<Grid container>
									<Grid item xs={1}></Grid>
									<Grid item xs={10}>
										<Skeleton width={`100%`} height={200} />
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={1}></Grid>
									<Grid item xs={10}>
										<Skeleton width={`100%`} height={200} />
									</Grid>
								</Grid>
							</li>
						))}
				</ul>
				<div
					css={css`
						padding: 2% 4%;
						background-color: rgb(84, 156, 164);
					`}
				>
					<Skeleton height={100} width={`100%`} />
				</div>
				<ul
					className="list"
					css={css`
						list-style-type: none;
						padding: 2% 3%;
					`}
				>
					{Array(3)
						.fill()
						.map((item, index) => (
							<li className="card" key={index}>
								<Grid container>
									<Grid item xs={1}></Grid>
									<Grid item xs={10}>
										<Skeleton width={`100%`} height={120} />
									</Grid>
								</Grid>
							</li>
						))}
				</ul>
				<div
					css={css`
						background-color: #294772;
						padding: 2% 4%;
					`}
				>
					<Skeleton height={100} width={`95%`} />
				</div>
			</div>
		);

	return (
		<div
			className="History"
			css={css`
				min-height: calc(100vh - 82px);
				display: flex;
				flex-direction: column;
			`}
		>
			<Grid container>
				<Grid
					item
					xs={12}
					css={css`
						color: white;
						background: linear-gradient(to right, #373b44, #4286f4);
					`}
				>
					<Hero data={data.historyPage.HistoryHeroCard} />
				</Grid>
				{/*<Grid
					item
					xs={12}
					css={css`
						padding-bottom: 3%;
						padding-top: 4%;
						background-color: #deebed;
						color: #2a4772;
					`}
				>
					<HistoryDescription
						data={data.historyPage.HistoryDescription}
						isMobile={isMobile}
					/>
				</Grid>*/}
				<Grid
					item
					xs={12}
					css={css`
						background-color: #deebed;
						color: #2a4772;
						padding-bottom: 2%;
						padding-top: 4%;
					`}
				>
					<HistoryBodyCard data={data.historyPage.HistoryBody} />
				</Grid>
				<Grid
					item
					xs={12}
					css={css`
						background-color: #deebed;
						color: #2a4772;
						padding-bottom: 4%;
					`}
				>
					{isMobile ? (
						<img
							src={TimelineMobile}
							css={css`
								display: block;
								margin-left: auto;
								margin-right: auto;
								width: 70%;
								height: auto;
							`}
							alt="Mobile History Timeline"
						/>
					) : (
						<img
							src={TimelineDesktop}
							css={css`
								display: block;
								margin-left: auto;
								margin-right: auto;
								width: 70%;
								height: auto;
							`}
							alt="Desktop History Timeline"
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					<HistoryBodyHero data={data.historyPage.HistoryBodyHeroCard} />
				</Grid>
				<Grid
					item
					xs={12}
					css={css`
						background-color: #deebed;
						color: #2a4772;
						padding-top: 4%;
						padding-bottom: 4%;
					`}
				>
					<HistoryAccordionBlock data={data.historyPage.HistoryAccordion} />
				</Grid>
				{/*<Grid
					item
					xs={12}
					css={css`
						background-color: #deebed;
						color: #2a4772;
						padding-bottom: 4%;
						padding-top: 4%;
					`}
				>
					<HistoryBodyCard
						data={data.historyPage.HistoryBodyBelowHero}
					/>
				</Grid>*/}
			</Grid>
			<Footer />
		</div>
	);
}

export default History;
