import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from './Home.js';
import History from './History.js';
import { Nav } from './Nav.js';
import NavMobile from './NavMobile.js';

import Partners from './Partners.js';
import OurTeam from './OurTeam.js';
import Themes from './Themes.js';
import Resources from './Resources.js';
import ScrollToTop from './ScrollToTop.js';
import Publications from './Publications.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import NotFound from './NotFound.js';

import GlobalFonts from './assets/GlobalFonts';
import styled from '@emotion/styled';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StyledWrapper = styled.div`
	font-family: 'Helvetica Neue', Arial, sans-serif;
`;

const Routes = () => {
	// breakpoint for desktop/mobile navbar(700px)
	const mobileBreakpoint = 700;
	// "isMobile" is true when window size < 600px
	const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);

	return (
		<div>
			<Router>
				<ScrollToTop />
				<GlobalFonts />
				<StyledWrapper>
					{isMobile ? <NavMobile /> : <Nav />}
					<Switch>
						<Route path="/" exact>
							<Home title="Fenot - Home" />
						</Route>
						<Route path="/history" exact>
							<History title="Fenot - History" />
						</Route>
						<Route path="/partners" exact>
							<Partners title="Fenot - Partners" />
						</Route>
						<Route path="/ourteam" exact>
							<OurTeam title="Fenot - Our Team" />
						</Route>
						<Route path="/themes/:slug" exact>
							<Themes title="Fenot - Themes" />
						</Route>
						<Route path="/resources" exact>
							<Resources title="Fenot - Resources" />
						</Route>
						<Route path="/publications" exact>
							<Publications title="Fenot - Publications" />
						</Route>
						<Route path="/privacy-policy" exact>
							<PrivacyPolicy />
						</Route>
						<Route path="/404" exact>
							<NotFound title="Page Not Found" />
						</Route>
					</Switch>
				</StyledWrapper>
			</Router>
		</div>
	);
};

export default Routes;
