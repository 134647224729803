/** @jsxRuntime classic */
/** @jsx jsx */

//for the material menu
import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Logo from './Logo.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from 'material-ui-popup-state/HoverMenu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { Link as RouterLink } from 'react-router-dom';

import { css, jsx } from '@emotion/core';
import { Link, useLocation } from 'react-router-dom';

const CustomButton = withStyles((theme) => ({
  root: {
    fontSize: 18,
    fontFamily: 'Helvetica Neue',
    padding: '10px 15px',
    textTransform: 'none',
    '&:hover': {
      //backgroundColor: "transparent !important",
      //color: "#549ca4",
    },
    borderRadius: 1,
    margin: '13px 5px',
  },
}))(Button);

//fixed the grey background before hover bug with setting the backgroundColor to transparent and important
const CustomMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 18,
    fontFamily: 'Helvetica Neue',
    borderBottom: '4px solid #2a4772',
    '&:hover': {
      //backgroundColor: "transparent !important",
      color: '#549ca4',
    },
    padding: '6px 10px',
    margin: '5px 5px 0 5px',
  },
}))(MenuItem);

const BottomMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 18,
    fontFamily: 'Helvetica Neue',
    '&:hover': {
      //backgroundColor: "transparent !important",
      color: '#549ca4',
    },
    padding: '6px 10px',
    margin: '0 5px 5px 5px',
  },
}))(MenuItem);

const CustomMenu = withStyles((theme) => ({
  root: {
    maxWidth: 20,
  },
}))(Menu);

const GET_THEMES = gql`
  query {
    themesPages(sort: "ThemesSlug:asc") {
      ThemesSlug
      ThemesHeroCard {
        NavigationTitle
      }
    }
  }
`;

export function Nav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const popupState = usePopupState({ variant: 'popover', popupId: 'about' });
  const popupStateThemes = usePopupState({
    variant: 'popover',
    popupId: 'themes',
  });

  let location = useLocation();

  const hoverStyles = () => css`
    border-top: ${location.pathname === '/history' ||
    location.pathname === '/partners'
      ? '5px solid black'
      : '5px solid transparent'};
    &:hover {
      color: #549ca4 !important;
      border-top: ${location.pathname === '/history' ||
      location.pathname === '/partners'
        ? '5px solid #549ca4'
        : '5px solid transparent'};
    }
  `;

  const hoverStylesOurTeam = () => css`
    border-top: ${location.pathname === '/ourteam'
      ? '5px solid black'
      : '5px solid transparent'};
    &:hover {
      color: #549ca4 !important;
      border-top: ${location.pathname === '/ourteam'
        ? '5px solid #549ca4'
        : '5px solid transparent'};
    }
  `;

  const hoverStylesThemes = () => css`
    border-top: ${location.pathname.indexOf('/themes') !== -1
      ? '5px solid black'
      : '5px solid transparent'};
    &:hover {
      color: #549ca4 !important;
      border-top: ${location.pathname.indexOf('/themes') !== -1
        ? '5px solid #549ca4'
        : '5px solid transparent'};
    }
  `;

  const hoverStylesResources = () => css`
    border-top: ${location.pathname === '/resources'
      ? '5px solid black'
      : '5px solid transparent'};
    &:hover {
      color: #549ca4 !important;
      border-top: ${location.pathname === '/resources'
        ? '5px solid #549ca4'
        : '5px solid transparent'};
    }
  `;

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl3(null);
  };

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  //for developing and testing purposes, use false, otherwise when ready to deploy use true or comment out and delete from the if statement
  const [minimumTimeElapsed] = useState(true);

  const { data, loading } = useQuery(GET_THEMES);

  if (!minimumTimeElapsed || loading)
    return (
      <div css={css``}>
        <div
          css={css`
            padding: 0 2% 1% 3%;
          `}
        >
          <Grid container css={css``} justify="space-between">
            <Grid item xs={2}>
              <Skeleton height={70} width={`100%`} />
            </Grid>
            <Grid item xs={5}>
              <Grid container css={css``} spacing={3}>
                <Grid item xs={3}>
                  <Skeleton height={70} width={`100%`} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={70} width={`100%`} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={70} width={`100%`} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={70} width={`100%`} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );

  return (
    <AppBar color="white" position="sticky" elevation={0}>
      <Grid
        container
        css={css`
      color: #373b44;
     }
    `}
      >
        <Grid
          item
          xs={1}
          css={css`
          padding-left: 1%;
          padding-top: 5px;
          img {
            width: 150px;
            margin-top: 10px;
          }
        }
        `}
        >
          <Link to="/">
            <img src={Logo} alt="Logo"></img>
          </Link>
        </Grid>
        <Grid
          container
          xs={11}
          justify="flex-end"
          css={css`
          color: #373b44;
        }
        `}
        >
          <Grid item>
            <CustomButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              onMouseOver={handleClick}
              {...bindHover(popupState)}
            >
              <div className="nav-button" css={hoverStyles}>
                <strong>About</strong>
              </div>
              <ArrowDropDownIcon />
            </CustomButton>
            <CustomMenu
              elevation={0}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                onMouseLeave: handleClose,
                disablePadding: true,
              }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{ square: true }}
              {...bindMenu(popupState)}
            >
              <CustomMenuItem
                onClick={(popupState.close, handleClose)}
                component={RouterLink}
                to="/history"
              >
                History
              </CustomMenuItem>
              <BottomMenuItem
                onClick={(popupState.close, handleClose)}
                component={RouterLink}
                to="/partners"
              >
                Partners
              </BottomMenuItem>
            </CustomMenu>
          </Grid>
          <Grid item>
            <CustomButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              component={RouterLink}
              to="/ourteam"
            >
              <div className="nav-button" css={hoverStylesOurTeam}>
                <strong>Our Team</strong>
              </div>
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onMouseEnter={(e) => setAnchorEl3(e.currentTarget)}
              onClick={(e) => setAnchorEl3(e.currentTarget)}
              {...bindHover(popupStateThemes)}
            >
              <div className="nav-button" css={hoverStylesThemes}>
                <strong>Themes</strong>
              </div>
              <ArrowDropDownIcon />
            </CustomButton>
            <Menu
              PaperProps={{ square: true }}
              elevation={0}
              anchorEl={anchorEl3}
              open={Boolean(anchorEl3)}
              onClose={handleClose}
              MenuListProps={{
                disablePadding: true,
                onMouseLeave: handleClose,
              }}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              {...bindMenu(popupStateThemes)}
            >
              {data.themesPages.map((themes, index, array) => {
                if (array.length - 1 === index) {
                  return (
                    <BottomMenuItem
                      style={{
                        whiteSpace: 'normal',
                        width: '120px',
                        height: '75px',
                      }}
                      onClick={(popupStateThemes.close, handleClose)}
                      component={RouterLink}
                      to={`/themes/${themes.ThemesSlug}`}
                    >
                      {themes.ThemesHeroCard.NavigationTitle}
                    </BottomMenuItem>
                  );
                } else {
                  return (
                    <CustomMenuItem
                      style={{
                        whiteSpace: 'normal',
                        width: '120px',
                        height: '75px',
                      }}
                      onClick={(popupStateThemes.close, handleClose)}
                      component={RouterLink}
                      to={`/themes/${themes.ThemesSlug}`}
                    >
                      {themes.ThemesHeroCard.NavigationTitle}
                    </CustomMenuItem>
                  );
                }
              })}
            </Menu>
          </Grid>
          <Grid item>
            <CustomButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              component={RouterLink}
              to="/resources"
            >
              <div className="nav-button" css={hoverStylesResources}>
                <strong>Resources</strong>
              </div>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}

/*export function Nav1() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
  };

  const { data, loading } = useQuery(GET_THEMES);
  if (loading) return null;

  return (
    <Grid
      container
      css={css`
      color: #373b44;
     }
    `}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        css={css`
          padding-left: 1%;
        }
        `}
      >
        <Link to="/">
          <img src={Logo} alt="Logo"></img>
        </Link>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={9}
        lg={9}
        justify="flex-end"
        css={css`
          color: #373b44;
        }
        `}
      >
        <Grid item>
          <CustomButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            disableRipple
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          >
            <Typography>About</Typography>
          </CustomButton>
          <CustomMenu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/history"
            >
              History
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/partners"
            >
              Partners
            </MenuItem>
          </CustomMenu>
        </Grid>
        <Grid item>
          <CustomButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            disableRipple
            component={RouterLink}
            to="/ourteam"
            indicatorColor="pink"
          >
            Our Team
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            disableRipple
            onMouseEnter={(e) => setAnchorEl3(e.currentTarget)}
            onClick={(e) => setAnchorEl3(e.currentTarget)}
          >
            Themes
          </CustomButton>
          <Menu
            anchorEl={anchorEl3}
            keepMounted
            open={!!anchorEl3}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {data.themesPages.map((themes) => (
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to={`/themes/${themes.ThemesSlug}`}
              >
                {themes.ThemesHeroCard.NavigationTitle}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item>
          <CustomButton
            aria-controls="simple-menu"
            disableRipple
            aria-haspopup="true"
            onClick={(e) => setAnchorEl2(e.currentTarget)}
            onMouseEnter={(e) => setAnchorEl2(e.currentTarget)}
          >
            Resources
          </CustomButton>
          <Menu
            anchorEl={anchorEl2}
            keepMounted
            open={!!anchorEl2}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/resources"
            >
              Resources
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/publications"
            >
              Publications
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}*/

/*export const Nav = () => (
  <nav>
    <ul
      css={css`
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
      `}
    >
      <li>
        <Link css={HeaderItemStyling} to="/">
          Home
        </Link>
      </li>
      <li>
        <Link css={HeaderItemStyling} to="/partners">
          Partners
        </Link>
      </li>
      <li>
        <Link css={HeaderItemStyling} to="/history">
          History
        </Link>
      </li>
      <li>
        <Link css={HeaderItemStyling} to="/ourteam">
          Our Team
        </Link>
      </li>
      <li>
        <Link css={HeaderItemStyling} to="/resources">
          Resources
        </Link>
      </li>
      <li>
        <Link css={HeaderItemStyling} to="/publications">
          Publications
        </Link>
      </li>
    </ul>
  </nav>
);*/
