// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Hero } from './Hero.js';
import { Footer } from './Footer.js';
import Teal from './teal.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledLinkWrapper from './assets/StyledLinkWrapper';

// mobile breakpoint (600px = Material-UI "xs" breakpoint)
const mobileBreakpoint = 600;

const Header2Styling = css`
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 23px;
	font-family: 'Roboto Slab';
	@media (max-width: ${mobileBreakpoint}px) {
		font-size: 22px;
	}
`;

const GET_PARTNERS = gql`
	query {
		partnerPage {
			PartnerHeroCard {
				Title
				Image {
					formats
					url
				}
			}
			LargePartnerCard {
				Partner
				Image {
					url
				}
				Address
			}
			SmallPartnerCard {
				Name
				Website
				Image {
					url
				}
			}
		}
	}
`;

const LargePartner = ({ data, isMobile }) => (
	<div className="large-partner-container">
		<Container maxWidth="lg">
			<Grid container justify="space-between" spacing={isMobile ? 5 : 10}>
				{data.map((LargePartners) => (
					<Grid item xs={12} sm={6}>
						{LargePartners.Image ? (
							<Grid
								item
								xs={12}
								css={css`
									 {
										height: 4em;
									}
								`}
							>
								<img
									css={css`
										 {
											width: auto;
											height: auto;
											max-width: 100%;
											max-height: 100%;
										}
									`}
									src={LargePartners.Image.url}
									alt="LargePartnerLogo"
								></img>
							</Grid>
						) : (
							<Grid
								item
								xs={12}
								css={css`
									 {
										height: 4em;
									}
								`}
							>
								<Paper
									square
									css={css`
										height: 100%;
										width: 95%;
										background-image: url(${Teal});
										background-position: center;
										background-size: 600px;
										background-repeat: no-repeat;
										@media (max-width: ${mobileBreakpoint}px) {
											width: 100%;
											height: grid-auto-columns;
											box-shadow: none !important;
										}
									`}
								/>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							css={css`
								padding-top: 5%;
								@media (max-width: ${mobileBreakpoint}px) {
									padding-top: 2%;
								}
							`}
						>
							<h2 css={Header2Styling}>
								{LargePartners.Partner}
							</h2>
							<div
								css={css`
									line-height: 30px;
									font-size: 18px;
									font-weight: 500;
									color: #353535;
									@media (max-width: ${mobileBreakpoint}px) {
										line-height: 1.4;
										margin-top: 10px;
										font-size: 16px;
									}
								`}
								dangerouslySetInnerHTML={{
									__html: LargePartners.Address,
								}}
							></div>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Container>
	</div>
);

const SmallPartner = ({ data }) => (
	<div
		className="small-partner-container"
		css={css`
			 {
				@media (max-width: ${mobileBreakpoint}px) {
					padding-top: 20px;
				}
			}
		`}
	>
		<Container maxWidth="lg">
			<Grid spacing={5} container justify="space-between">
				{data.map((SmallPartners) => (
					<Grid item xs={12} sm={4}>
						{SmallPartners.Image ? (
							<Grid container>
								<Grid
									item
									xs={12}
									css={css`
										 {
											height: 45px;
										}
									`}
								>
									<img
										css={css`
											 {
												max-height: 100%;
												max-width: 100%;
											}
										`}
										src={SmallPartners.Image.url}
										alt="LargePartnerLogo"
									></img>
								</Grid>
								<Grid
									item
									xs={12}
									css={css`
										padding-top: 5%;
										@media (max-width: ${mobileBreakpoint}px) {
											padding-top: 2.1%;
										}
									`}
								>
									<h2 css={Header2Styling}>
										{SmallPartners.Name}
									</h2>
									<p
										css={css`
											line-height: 30px;
											font-size: 18px;
											font-weight: 500;
											color: #353535;
											word-break: break-all;
											@media (max-width: ${mobileBreakpoint}px) {
												line-height: 1.4;
												font-size: 14px;
												margin-top: 1%;
											}
										`}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: SmallPartners.Website,
											}}
										></div>
									</p>
								</Grid>
							</Grid>
						) : (
							<Grid container>
								<Grid item xs={12}>
									<Paper
										square
										css={css`
											width: auto;
											height: auto;
											max-height: 100%;
											max-width: 90%;
											background-image: url(${Teal});
											background-position: center;
											background-size: 500px;
											background-repeat: no-repeat;
										`}
									/>
									<Grid
										item
										xs={12}
										css={css`
											padding-top: 5%;
										`}
									>
										<h2 css={Header2Styling}>
											{SmallPartners.Name}
										</h2>
										<p
											css={css`
												line-height: 30px;
												font-size: 18px;
												font-weight: 500;
												color: #353535;
												word-break: break-all;
												@media (max-width: ${mobileBreakpoint}px) {
													line-height: 1.4;
													font-size: 14px;
													margin-top: 1%;
												}
											`}
										>
											<div
												dangerouslySetInnerHTML={{
													__html:
														SmallPartners.Website,
												}}
											></div>
										</p>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				))}
			</Grid>
		</Container>
	</div>
);

export function Partners(props) {
	useEffect(() => {
		document.title = props.title || 'Fenot';
	}, [props.title]);

	// "isMobile" is true when window size < 600px
	const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);

	const [minimumTimeElapsed] = useState(true);

	const { data, loading } = useQuery(GET_PARTNERS);

	if (!minimumTimeElapsed || loading)
		return (
			<div
				css={css`
					background-color: #deebed;
				`}
			>
				<div
					css={css`
						padding: 2% 3% 2.5% 3%;
						background-color: rgb(84, 156, 164);
					`}
				>
					<Skeleton height={100} width={`32%`} />
				</div>
				<ul
					className="list"
					css={css`
						list-style-type: none;
						padding: 2% 3%;
					`}
				>
					{Array(1)
						.fill()
						.map((item, index) => (
							<li className="card" key={index}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Skeleton width={`95%`} height={200} />
									</Grid>
									<Grid item xs={6}>
										<Skeleton width={`95%`} height={200} />
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<Skeleton width={`95%`} height={200} />
									</Grid>
									<Grid item xs={4}>
										<Skeleton width={`95%`} height={200} />
									</Grid>
									<Grid item xs={4}>
										<Skeleton width={`95%`} height={200} />
									</Grid>
								</Grid>
							</li>
						))}
				</ul>
				<div
					css={css`
						background-color: #294772;
						padding: 2% 4%;
					`}
				>
					<Skeleton height={100} width={`95%`} />
				</div>
			</div>
		);

	return (
		<div
			className="Partners"
			css={css`
				min-height: calc(100vh - 82px);
				display: flex;
				flex-direction: column;
			`}
		>
			<StyledLinkWrapper>
				<div
					className="hero-container"
					css={css`
						color: white;
					`}
				>
					<Hero data={data.partnerPage.PartnerHeroCard} />
				</div>
				<div
					className="large-partners-container"
					css={css`
						padding: 4% 0 1%;
						background-color: #deebed;
						color: #2a4772;
						@media (max-width: ${mobileBreakpoint}px) {
							padding-top: 10%;
						}
					`}
				>
					<LargePartner
						data={data.partnerPage.LargePartnerCard}
						isMobile={isMobile}
					/>
				</div>
				<div
					className="small-partners-container"
					css={css`
						padding: 4% 0 20%;
						background-color: #deebed;
						color: #2a4772;
						@media (max-width: ${mobileBreakpoint}px) {
							padding-bottom: 15%;
						}
					`}
				>
					<SmallPartner data={data.partnerPage.SmallPartnerCard} />
				</div>
			</StyledLinkWrapper>
			<Footer />
		</div>
	);
}

export default Partners;
