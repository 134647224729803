// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Footer } from './Footer.js';
import Stock from './Stock.jpg';
import White from './white.svg';
import Teal from './teal.svg';
import { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledLinkWrapper from './assets/StyledLinkWrapper';

import useMediaQuery from '@material-ui/core/useMediaQuery';

// mobile breakpoint (600px = Material-UI "xs" breakpoint)
const mobileBreakpoint = 600;

const GET_HOMEPAGE = gql`
  query {
    homePage {
      HomeHeroCard {
        PreTitle
        Title
        SubTitle
        Description
        Image {
          formats
          url
        }
      }
      StrategicObjectives {
        Title
        Description
        Image {
          formats
        }
      }
      HomeBodyText {
        Body
      }
    }
  }
`;

const ParagraphStyling = css`
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  line-height: 24px;
  font-size: 16px;
  color: #353535;
  @media (max-width: ${mobileBreakpoint}px) {
    padding: 2% 5% 2% 5%;
  }
`;

const HorizontalLineStyling = css`
  border-top: 5px solid #2a4772;
  width: 50%;
  margin: 4% 0 4% 0;
  @media (max-width: ${mobileBreakpoint}px) {
    width: 89%;
    margin: 0 0 0 5%;
  }
`;

const Hero = ({ data: { PreTitle, Title, SubTitle, Description, Image } }) => (
  <div>
    {Image ? (
      <div
        className="title-container"
        css={css`
          padding: 10% 0 5% 0;
          background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url(${Image.url});
          @media (max-width: ${mobileBreakpoint}px) {
            background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
              url(${Image.formats.large.url});
            background-position: center;
            background-size: cover;
          }
          background-position: center;
          background-size: cover;
        `}
      >
        <Grid container>
          <Grid
            item
            xs={1}
            css={css`
              padding-bottom: 10%;
            `}
          ></Grid>
          <Grid
            item
            xs={10}
            css={css`
              padding-bottom: 3%;
            `}
          >
            <h3
              css={css`
                margin-block-start: 0;
                margin-block-end: 0;
                font-family: 'Rokkitt';
                font-size: 24px;
                line-height: 0.7;
                @media (max-width: ${mobileBreakpoint}px) {
                  line-height: 0.9;
                }
              `}
              className="hero-pre-title"
            >
              {PreTitle}
            </h3>
            <h1
              css={css`
                font-size: calc(40px + 5vw);
                margin-block-start: 0;
                margin-block-end: 0;
                line-height: 0.9;
                margin-left: -4px;
              `}
            >
              {Title}
            </h1>
            <p
              css={css`
                margin-block-start: 0;
                margin-block-end: 0;
                font-size: 16px;
                line-height: 18px;
              `}
              className="hero-pre-title"
            >
              {SubTitle}
            </p>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Paper
              square
              css={css`
                height: 100%;
                width: 90%;
                background-image: url(${White});
                background-position: center;
                background-size: 600px;
                background-repeat: no-repeat;
              `}
            />
          </Grid>
          <Grid item xs={10} sm={8}>
            <div
              css={css`
                p {
                  margin-block-start: 0;
                  margin-block-end: 0;
                }
                font-size: 18px;
                line-height: 20px;
                font-family: 'Rokkitt';
              `}
              dangerouslySetInnerHTML={{ __html: Description }}
            ></div>
          </Grid>
        </Grid>
      </div>
    ) : (
      <div
        className="title-container"
        css={css`
          padding: 10% 0 5% 0;
          background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url(${Stock});
          background-position: center;
          background-size: cover;
        `}
      >
        <Grid container>
          <Grid
            item
            xs={1}
            css={css`
              padding-bottom: 10%;
            `}
          ></Grid>
          <Grid
            item
            xs={11}
            css={css`
              padding-bottom: 3%;
            `}
          >
            <h3
              css={css`
                margin-block-start: 0;
                margin-block-end: 0;
                font-family: 'Rokkitt';
                font-size: 24px;
                line-height: 0.7;
              `}
              className="hero-pre-title"
            >
              {PreTitle}
            </h3>
            <h1
              css={css`
                font-size: calc(40px + 5vw);
                margin-block-start: 0;
                margin-block-end: 0;
                line-height: 0.9;
                margin-left: -6px;
              `}
            >
              {Title}
            </h1>
            <p
              css={css`
                margin-block-start: 0;
                margin-block-end: 0;
                font-size: 16px;
                line-height: 18px;
              `}
              className="hero-pre-title"
            >
              {SubTitle}
            </p>
          </Grid>
          <Grid item xs={1}>
            <Paper
              square
              css={css`
                height: 100%;
                width: 90%;
                background-image: url(${White});
                background-position: center;
                background-size: 600px;
                background-repeat: no-repeat;
              `}
            />
          </Grid>
          <Grid item xs={8}>
            <div
              css={css`
                p {
                  margin-block-start: 0;
                  margin-block-end: 0;
                }
                font-size: 18px;
                line-height: 20px;
                font-family: 'Rokkitt';
              `}
              dangerouslySetInnerHTML={{ __html: Description }}
            ></div>
          </Grid>
        </Grid>
      </div>
    )}
  </div>
);

//this component changes order when on Mobile
//the isMobile ternary at the beginning determines the order of the items in the component, specifically
//the image on the right joins the "squiggly" above the title and the text
const StrategicObjective = ({ data, isMobile }) => (
  <div
    css={css`
      max-width: 100vw;
      overflow: hidden;
    `}
  >
    {isMobile
      ? data.map((strategicObjective) => (
          <Grid
            container
            css={css`
               {
                padding-top: 10%;
              }
            `}
          >
            <Grid item xs={3} sm={1}>
              <Paper
                square
                css={css`
                  height: 220px;
                  width: 100%;
                  background-image: url(${Teal});
                  background-position: center;
                  background-repeat: no-repeat;
                  box-shadow: none !important;
                  background-size: 350%;
                `}
              />
            </Grid>
            {strategicObjective.Image ? (
              <Grid item xs={9}>
                <div
                  className="strategic-objective-column strategic-objective-image"
                  css={css`
                    height: 100%;
                    background-image: url(${strategicObjective.Image.formats
                      .medium.url});
                    background-position: center;
                    background-size: cover;
                    @media (max-width: ${mobileBreakpoint}px) {
                      width: 100%;
                      box-shadow: none !important;
                    }
                  `}
                ></div>
              </Grid>
            ) : (
              <Grid item xs={9}>
                <div
                  className="strategic-objective-column strategic-objective-image"
                  css={css`
                    height: 100%;
                    background-image: url(${Stock});
                    background-position: center;
                    background-size: cover;
                    @media (max-width: ${mobileBreakpoint}px) {
                      width: 100%;
                      box-shadow: none !important;
                      background-size: 350%;
                    }
                  `}
                ></div>
              </Grid>
            )}
            <Grid item xs={9}>
              <h1
                css={css`
                   {
                    margin-block-start: 0;
                    margin-block-end: 0;
                    line-height: 1.2;
                    padding: 5% 5% 3.3% 7%;
                    font-family: 'Roboto Slab';
                  }
                `}
              >
                {strategicObjective.Title}
              </h1>
            </Grid>
            <hr css={HorizontalLineStyling}></hr>
            <Grid item xs={12} sm={11}>
              <div
                css={ParagraphStyling}
                dangerouslySetInnerHTML={{
                  __html: strategicObjective.Description,
                }}
              ></div>
            </Grid>
          </Grid>
        ))
      : data.map((strategicObjective) => (
          <Grid
            container
            css={css`
               {
                padding-top: 4%;
                padding-bottom: 1%;
              }
            `}
          >
            <Grid item xs={3} sm={1}>
              <div
                className="team-column solid"
                css={css`
                   {
                    height: 100%;
                    width: 90%;
                    background-image: url(${Teal});
                    background-position: left;
                    background-size: 800px;
                    background-repeat: no-repeat;
                  }
                `}
              ></div>
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={7}>
                  <h1
                    css={css`
                       {
                        margin-block-start: 0;
                        margin-block-end: 0;
                        line-height: 43px;
                        font-family: 'Roboto Slab';
                      }
                    `}
                  >
                    {strategicObjective.Title}
                  </h1>
                </Grid>
                <Grid item xs={12}>
                  <hr css={HorizontalLineStyling}></hr>
                  <div
                    css={css`
                       {
                        p {
                          margin-block-start: 0;
                          margin-block-end: 0;
                        }
                        line-height: 30px;
                        font-size: 18px;
                        font-weight: 500;
                      }
                    `}
                    dangerouslySetInnerHTML={{
                      __html: strategicObjective.Description,
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Grid>
            {strategicObjective.Image ? (
              <Grid item xs={5}>
                <div
                  className="strategic-objective-column strategic-objective-image"
                  css={css`
                    width: 90%;
                    height: 100%;
                    margin-left: 10%;
                    background-image: url(${strategicObjective.Image.formats
                      .medium.url});
                    background-position: center;
                    background-size: cover;
                  `}
                ></div>
              </Grid>
            ) : (
              <Grid item xs={5}>
                <div
                  className="strategic-objective-column strategic-objective-image"
                  css={css`
                    width: 90%;
                    height: 100%;
                    margin-left: 10%;
                    background-image: url(${Stock});
                    background-position: center;
                    background-size: cover;
                  `}
                ></div>
              </Grid>
            )}
          </Grid>
        ))}
  </div>
);

const HomeBody = ({ data }) => (
  <div
    className="home-body-container"
    css={css`
      padding: 4% 0;
    `}
  >
    {data.map((HomeBody) => (
      <Grid container>
        <Grid item xs={0} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
          <div
            dangerouslySetInnerHTML={{
              __html: HomeBody.Body,
            }}
            css={ParagraphStyling}
          ></div>
          <br></br>
        </Grid>
      </Grid>
    ))}
  </div>
);

export function Home(props) {
  useEffect(() => {
    document.title = props.title || 'Fenot';
  }, [props.title]);

  const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);

  //for developing and testing purposes, use false, otherwise when ready to deploy use true or comment out and delete from the if statement
  const [minimumTimeElapsed] = useState(true);

  const { data, loading } = useQuery(GET_HOMEPAGE);

  if (!minimumTimeElapsed || loading)
    return (
      <div
        css={css`
          background-color: #deebed;
        `}
      >
        <div
          css={css`
            padding: 2% 3%;
            background-color: rgb(84, 156, 164);
          `}
        >
          <Skeleton height={180} width={`50%`} />
          <Skeleton width={`60%`} height={50} />
          <Skeleton height={180} width={`75%`} />
        </div>
        <ul
          className="list"
          css={css`
            list-style-type: none;
            padding: 2% 3%;
          `}
        >
          {Array(1)
            .fill()
            .map((item, index) => (
              <li className="card" key={index}>
                <Grid container>
                  <Grid item xs={2}>
                    <Skeleton width={`95%`} height={150} />
                  </Grid>
                  <Grid item xs={5}>
                    <Skeleton width={`95%`} height={150} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2}>
                    <Skeleton width={`95%`} height={150} />
                  </Grid>
                  <Grid item xs={5}>
                    <Skeleton width={`95%`} height={150} />
                  </Grid>
                </Grid>
                <Skeleton height={100} width={`95%`} />
                <Skeleton height={100} width={`95%`} />

                <Skeleton height={20} width={`95%`} />
                <Skeleton height={20} width={`95%`} />
                <Skeleton height={20} width={`95%`} />
              </li>
            ))}
        </ul>
        <div
          css={css`
            background-color: #294772;
            padding: 2% 4%;
          `}
        >
          <Skeleton height={100} width={`95%`} />
        </div>
      </div>
    );

  return (
    <div
      className="Home"
      css={css`
        min-height: calc(100vh - 82px);
        display: flex;
        flex-direction: column;
      `}
    >
      {/*<pre>{JSON.stringify(data.homePages[0].HomeHeroCard.Title, null, 3)}</pre>*/}
      <StyledLinkWrapper>
        <Grid container>
          <Grid item>
            <div
              className="hero-container"
              css={css`
                display: flex;
                flex-direction: column;
                align-items: left;
                justify-content: center;
                color: white;
              `}
            >
              <Hero data={data.homePage.HomeHeroCard} />
            </div>
          </Grid>
          <Grid item>
            <div
              className="home-container"
              css={css`
                background-color: #deebed;
                color: #2a4772;
              `}
            >
              <StrategicObjective
                data={data.homePage.StrategicObjectives}
                isMobile={isMobile}
              />
              <HomeBody data={data.homePage.HomeBodyText} />
            </div>
          </Grid>
        </Grid>
      </StyledLinkWrapper>
      <Footer />
    </div>
  );
}
