// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Hero } from './Hero.js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Footer } from './Footer.js';
import Email from './Email.svg';
import Twitter from './Twitter.svg';
import Teal from './teal.svg';
import { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledLinkWrapper from './assets/StyledLinkWrapper';

// mobile breakpoint (600px = Material-UI "xs" breakpoint)
const mobileBreakpoint = 600;

const HorizontalLineStyling = css`
	border-top: 7px solid #2a4772;
	width: 13%;
	margin: 1% 0 1% 0;
	@media (max-width: ${mobileBreakpoint}px) {
		width: 89%;
		margin: 0 0 0 5%;
	}
`;

const ParagraphStyling = css`
	p {
		margin-block-start: 0;
		margin-block-end: 0;
	}
	line-height: 19px;
	font-size: 14px;
	color: #353535;
	padding-top: 1%;
	@media (max-width: ${mobileBreakpoint}px) {
		padding: 0 5% 5% 5%;
	}
`;

const GET_OURTEAM = gql`
	query {
		ourTeamPage {
			OurTeamHeroCard {
				Title
				Image {
					url
					formats
				}
			}
			TeamMemberCard {
				Name
				Role
				Email
				Twitter
				Bio {
					BioParagraph
				}
				Photo {
					url
				}
			}
		}
	}
`;

const TeamMemberCards = ({ data, isMobile }) => (
	<div
		className="team-container"
		css={css`
			max-width: 100vw;
			overflow: hidden;
		`}
	>
		{data.map((TeamMember) => (
			<Grid
				container
				css={css`
					 {
						padding-bottom: 3%;
					}
				`}
			>
				<Grid item xs={3} sm={1}>
					<Paper
						square
						css={css`
							height: 220px;
							width: 90%;
							background-image: url(${Teal});
							background-position: center;
							background-size: 600px;
							background-repeat: no-repeat;
							@media (max-width: ${mobileBreakpoint}px) {
								width: 100%;
								box-shadow: none !important;
								background-size: 350%;
								${!TeamMember.Photo && 'height: 80px;'}
							}
						`}
					/>
				</Grid>
				{TeamMember.Photo ? (
					<Grid item xs={9} sm={2}>
						<Paper
							elevation={0}
							square
							css={css`
								height: 220px;
								background-image: url(${TeamMember.Photo.url});
								background-position: center;
								background-size: cover;
								background-repeat: no-repeat;
								margin-right: 15%;
								margin-left: 7%;
								@media (max-width: ${mobileBreakpoint}px) {
									width: 100%;
									background-position: center center;
									margin-left: 0;
									margin-right: 0;
								}
							`}
						/>
					</Grid>
				) : (
					<Grid item xs={9} sm={2}>
						<Paper
							elevation={0}
							square
							css={css`
								height: 220px;
								background-color: #549ca4 !important;
								margin-right: 15%;
								margin-left: 7%;
								@media (max-width: ${mobileBreakpoint}px) {
									width: 100%;
									background-position: center center;
									background-size: cover;
									margin-left: 0;
									margin-right: 0;
									${!TeamMember.Photo && 'height: 80px;'}
								}
							`}
						/>
					</Grid>
				)}
				<Grid item xs={12} sm={9}>
					<Grid
						container
						spacing={!isMobile && 5}
						css={css`
							@media (max-width: ${mobileBreakpoint}px) {
								margin: 0 !important;
							}
						`}
					>
						<Grid item xs={8} sm={9}>
							<h1
								css={css`
									 {
										margin-block-start: 0;
										margin-block-end: 0;
										font-size: 32px;
										font-family: 'Roboto Slab';
										line-height: 43px;
										@media (max-width: ${mobileBreakpoint}px) {
											line-height: 1.2;
											padding: 5% 5% 3.3% 7%;
										}
									}
								`}
							>
								{TeamMember.Name}
							</h1>
						</Grid>
						{TeamMember.Email || TeamMember.Twitter ? (
							<Grid item xs={3} sm={2}>
								<Grid container direction="row-reverse">
									{TeamMember.Email ? (
										<Grid
											item
											css={css`
												 {
													@media (max-width: ${mobileBreakpoint}px) {
														padding-top: 15px;
													}
												}
											`}
										>
											<a
												href={`mailto: ${TeamMember.Email}`}
											>
												<img
													src={Email}
													alt="Twitter"
												></img>
											</a>
										</Grid>
									) : null}
									{TeamMember.Twitter ? (
										<Grid
											item
											css={css`
												 {
													@media (max-width: ${mobileBreakpoint}px) {
														padding-top: 15px;
													}
												}
											`}
										>
											<a
												target="_blank"
												href={`https://twitter.com/${TeamMember.Twitter}`}
												rel="noreferrer"
											>
												<img
													src={Twitter}
													alt="Twitter"
												></img>
											</a>
										</Grid>
									) : null}
								</Grid>
							</Grid>
						) : (
							''
						)}
					</Grid>
					<hr css={HorizontalLineStyling}></hr>
					<h2
						css={css`
							 {
								margin-block-start: 0;
								margin-block-end: 0;
								font-size: 18px;
								font-weight: 500;
								line-height: 30px;
								@media (max-width: ${mobileBreakpoint}px) {
									padding: 1% 5% 5%;
								}
							}
						`}
					>
						{TeamMember.Role.toUpperCase()}
					</h2>
					<Grid item xs={12} sm={11}>
						{TeamMember.Bio.map((Bio) => (
							<div
								css={ParagraphStyling}
								dangerouslySetInnerHTML={{
									__html: Bio.BioParagraph,
								}}
							></div>
						))}
					</Grid>
				</Grid>
			</Grid>
		))}
	</div>
);

export function OurTeam(props) {
	useEffect(() => {
		document.title = props.title || 'Fenot';
	}, [props.title]);

	// "isMobile" is true when window size < 600px
	const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);

	//for developing and testing purposes, use false, otherwise when ready to deploy use true or comment out and delete from the if statement
	const [minimumTimeElapsed] = useState(true);

	const { data, loading } = useQuery(GET_OURTEAM);

	if (!minimumTimeElapsed || loading)
		return (
			<div
				css={css`
					background-color: #deebed;
				`}
			>
				<div
					css={css`
						padding: 2% 3% 2.5% 3%;
						background-color: rgb(84, 156, 164);
					`}
				>
					<Skeleton height={100} width={`30%`} />
				</div>
				<ul
					className="list"
					css={css`
						list-style-type: none;
						padding: 5% 3% 2% 6%;
					`}
				>
					{Array(3)
						.fill()
						.map((item, index) => (
							<li className="card" key={index}>
								<Grid
									container
									css={css`
										padding-bottom: 2%;
									`}
								>
									<Grid item xs={2}>
										<Skeleton
											variant="circle"
											width={150}
											height={150}
										/>
									</Grid>
									<Grid item xs={9}>
										<Skeleton width={`30%`} height={50} />
										<Skeleton width={`34%`} height={50} />
										<Skeleton width={`95%`} height={200} />
									</Grid>
								</Grid>
							</li>
						))}
				</ul>
				<div
					css={css`
						background-color: #294772;
						padding: 2% 4%;
					`}
				>
					<Skeleton height={100} width={`95%`} />
				</div>
			</div>
		);

	return (
		<div
			className="OurTeam"
			css={css`
				min-height: calc(100vh - 82px);
				display: flex;
				flex-direction: column;
			`}
		>
			<StyledLinkWrapper>
				<Grid container css={css``}>
					<Grid
						item
						xs={12}
						css={css`
							color: white;
							background: linear-gradient(
								to right,
								#373b44,
								#4286f4
							);
						`}
					>
						<Hero data={data.ourTeamPage.OurTeamHeroCard} />
					</Grid>
					<Grid
						item
						xs={12}
						css={css`
							padding-bottom: 1%;
							padding-top: 4%;
							background-color: #deebed;
							color: #2a4772;
						`}
					>
						<TeamMemberCards
							data={data.ourTeamPage.TeamMemberCard}
							isMobile={isMobile}
						/>
					</Grid>
				</Grid>
			</StyledLinkWrapper>
			<Footer />
		</div>
	);
}

export default OurTeam;
