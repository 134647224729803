// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import { Footer } from './Footer.js';
import { Hero } from './Hero.js';
import Stock from './Stock.jpg';

export default function NotFound() {
	return (
		<div>
			<Grid container justify="center">
				<Grid
					item
					xs={12}
					css={css`
						color: white;
						background: linear-gradient(
								rgba(0, 0, 0, 0.3),
								rgba(0, 0, 0, 0.3)
							),
							url(${Stock});
						background-position: center;
						background-size: cover;
					`}
				>
					<Hero data={('', '')} />
				</Grid>
				<Grid
					item
					css={css`
						 {
							padding: 10% 0;
						}
					`}
				>
					<h1
						css={css`
							 {
								margin-block-start: 0;
								margin-block-end: 0;
								line-height: 43px;
								font-family: 'Roboto Slab';
								color: #2a4772;
							}
						`}
					>
						Page Not Found
					</h1>
				</Grid>
			</Grid>
			<Footer />
		</div>
	);
}
