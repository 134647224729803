import React from "react";
import { Global, css } from "@emotion/core";
import RobotoSlab from "./fonts/RobotoSlab-VariableFont_wght.ttf";
import Rokkitt from "./fonts/Rokkitt-VariableFont_wght.ttf";

const GlobalFonts = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 500;
          src: url(${RobotoSlab});
        }
        @font-face {
          font-family: "Rokkitt";
          font-style: normal;
          font-weight: 500;
          src: url(${Rokkitt});
        }
      `}
    />
  );
};

export default GlobalFonts;
