/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import White from './white.svg';

// mobile breakpoint (600px = Material-UI "xs" breakpoint)
const mobileBreakpoint = 600;

export const Hero = ({ data: { Title, Image } }) => (
	<div>
		{Image ? (
			<div
				className="title-container"
				css={css`
					padding: 4% 0 4% 0;
					background-image: url(${Image.url});
					background-position: center;
					background-size: cover;
					@media (max-width: ${mobileBreakpoint}px) {
						background-image: url(${Image.formats.medium.url});
					}
					height: 80%;
				`}
			>
				<Grid container>
					<Grid item xs={1}>
						<Paper
							square
							css={css`
								height: 100%;
								width: 90%;
								background-image: url(${White});
								background-position: center;
								background-size: 600px;
								background-repeat: no-repeat;
							`}
						/>
					</Grid>
					<Grid item xs={11}>
						<div
							css={css`
								font-size: calc(28px + 1.7vw);
								p {
									margin-block-start: 0;
									margin-block-end: 0;
								}
								line-height: 1;
								font-weight: 600;
								@media (max-width: 600px) {
									font-size: 42px;
									-ms-word-break: break-all;
									word-break: break-all;

									/* Non standard for WebKit */
									word-break: break-word;

									-webkit-hyphens: auto;
									-moz-hyphens: auto;
									hyphens: auto;
								}
							`}
							dangerouslySetInnerHTML={{ __html: Title }}
						></div>
					</Grid>
				</Grid>
			</div>
		) : (
			<div
				className="title-container"
				css={css`
					padding: 4% 0 4% 0;
					background-position: center;
					background-size: cover;
				`}
			>
				<Grid container>
					<Grid item xs={1}>
						<Paper
							square
							css={css`
								height: 100%;
								width: 90%;
								background-image: url(${White});
								background-position: center;
								background-size: 600px;
								background-repeat: no-repeat;
							`}
						/>
					</Grid>
					<Grid item xs={11}>
						<div
							css={css`
								font-size: calc(28px + 1.7vw);
								p {
									margin-block-start: 0;
									margin-block-end: 0;
								}
								line-height: 1;
								font-weight: 600;
								@media (max-width: 600px) {
									font-size: 42px;
									-ms-word-break: break-all;
									word-break: break-all;

									/* Non standard for WebKit */
									word-break: break-word;

									-webkit-hyphens: auto;
									-moz-hyphens: auto;
									hyphens: auto;
								}
							`}
							dangerouslySetInnerHTML={{ __html: Title }}
						></div>
					</Grid>
				</Grid>
			</div>
		)}
	</div>
);
